import { connect } from "react-redux";
import AddressList from "./components";
import {
  getUserAddresses,
  deleteAddress,
  changeCheckoutForm,
  setDefaultUserAddress,
} from "../../../../actions";

function mapDispatchToProps(dispatch) {
  return {
    getUserAddresses: (userId, token) => {
      dispatch(getUserAddresses(userId, token));
    },
    deleteAddress: (addressId) => {
      dispatch(deleteAddress(addressId));
    },
    changeCheckoutForm: (contact) => {
      dispatch(changeCheckoutForm(contact));
    },
    setDefaultUserAddress: (token, userId, addressId) => {
      dispatch(setDefaultUserAddress(token, userId, addressId));
    },
  };
}

const mapStateToProps = (state) => ({
  token: state.user.token,
  dbUser: state.user.dbUser,
  addresses: state.user.addresses,
  checkoutForm: state.cart.checkoutForm,
});

export default connect(mapStateToProps, mapDispatchToProps)(AddressList);
