import { connect } from "react-redux";
import {
  ACTION_TYPES,
  changeAuthorsSort,
  changeSearchCriteria,
  getAuthors,
} from "../../../../../actions";
import AuthorFilterBar from "./components";

const mapDispatchToProps = (dispatch) => ({
  changeAuthorsSort: (sort) => dispatch(changeAuthorsSort(sort)),
  changeSearchCriteria: (criteria, stateHandler, actionType) =>
    dispatch(changeSearchCriteria(criteria, stateHandler, actionType)),
  getAuthors: (params) => dispatch(getAuthors(params)),
});

const mapStateToProps = (state) => ({
  actionType: ACTION_TYPES.AUTHORS_SEARCH_CRITERIA,
  authorsSort: state.sorts.currentAuthorsSort,
  filterParams: state.search.authorsFilter,
  authors: state.authors.authors,
});

export default connect(mapStateToProps, mapDispatchToProps)(AuthorFilterBar);
