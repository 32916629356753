import React from "react";
import { connect } from "react-redux";

import Results from "./components";

const Search = (props) => {
  return (
    <Results
      books={props.searchLiterature}
      audios={props.searchAudio}
      courses={props.searchCourses}
    />
  );
};

const mapStateToProps = (state) => ({
  searchLiterature: state.search.searchLiterature,
  searchAudio: state.search.searchAudio,
  searchCourses: state.search.searchCourses,
});

export default connect(mapStateToProps)(Search);
