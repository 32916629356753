import React from "react";
import { useTranslation } from "react-i18next";
import { useAuth0 } from "@auth0/auth0-react";

const CheckoutPaypalButton = (props) => {
  const { t } = useTranslation();
  const { loginWithRedirect } = useAuth0();
  return (
    <div className="text-relevant">
      <h2>{t("donate")}</h2>
      <p>
        {t("donate-smoother-login")}{" "}
        <button
          className="btn btn-primary btn-sm"
          onClick={async () =>
            await loginWithRedirect({
              appState: { returnTo: window.location.pathname },
            })
          }
        >
          {t("login")}
        </button>
      </p>
      <p>{t("donate-with-paypal")}</p>
      <a
        className="btn btn-primary btn-sm"
        href="https://collectcheckout.com/r/twr19"
        target="_blank"
      >
        {t("donate")}
      </a>
    </div>
  );
};

export default CheckoutPaypalButton;
