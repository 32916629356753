import React from "react";
import "./style.css";

const Footer = () => {
  return (
    <footer className="bd-footer">
      <div className="container">
        <div className="header row">
          <div className="col-12 col-md-4">
            <h5>Chapel Library</h5>
            <h6>2603 West Wright Street Pensacola, Florida 32505 USA</h6>
            <p>
              email:
              <a href="mailto:chapel@mountzion.org ">
                chapel@mountzion.org
              </a>{" "}
              phone: 850 438-6666 fax: 850 438-0227
            </p>
            <p>
              Mount Zion Bible Institute:
              <a href="mailto:school@mountzion.org ">school@mountzion.org</a>
            </p>
          </div>
          <div className="col-12 col-md-4">
            <h5>
              <a href="https://www.spurgeongems.org">Spurgeon Gems</a>
            </h5>
            <h6>
              <a href="https://www.spurgeongems.org">www.SpurgeonGems.org</a> -
              complete sermons of the "Prince of Preachers," also brought to you
              by Mt Zion.
            </h6>
          </div>
          <div className="social-networks col-12 col-md-4 ">
            <div className="row">
              <div className="col-md-6 social-networks text-sm-center text-md-right">
                <a
                  href="https://www.facebook.com/chapellibrary/"
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="Visit us on Facebook"
                  style={{ display: "inline-block", width: "100px" }}
                >
                  <i className="fa fab fa-facebook-square"></i>
                </a>
                <a
                  href="https://twitter.com/fgbroadcaster"
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="Visit us on Twitter"
                  style={{ display: "inline-block", width: "100px" }}
                >
                  <i className="fa fab fa-twitter-square"></i>
                </a>
              </div>
              <div className="col-md-6 text-sm-center text-md-right">
                <a
                  href="https://apps.apple.com/us/app/chapel-library-books-audio/id1660821361?itsct=apps_box_badge&amp;itscg=30200"
                  style={{
                    display: "inline-block",
                    overflow: "hidden",
                    borderRadius: "8px",
                    width: "145px",
                    height: "62px",
                  }}
                >
                  <img
                    src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/white/en-us?size=250x83&amp;releaseDate=1674172800"
                    alt="Download on the App Store"
                    style={{
                      borderRadius: "8px",
                      width: "125px",
                      height: "42px",
                    }}
                  />
                </a>
                <a
                  href="https://play.google.com/store/apps/details?id=org.chapellibrary.chapel&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
                  style={{
                    display: "inline-block",
                    overflow: "hidden",
                    width: "168px",
                    height: "68px",
                  }}
                >
                  <img
                    alt="Get it on Google Play"
                    src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
                    style={{
                      borderRadius: "13px",
                      width: "160px",
                      height: "62px",
                    }}
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
        <p>
          <span style={{ opacity: 0.6 }}>
            © 1998-{new Date().getFullYear()}, Mount Zion Bible Church. All
            rights reserved.
          </span>
        </p>
      </div>
    </footer>
  );
};

export default Footer;
