import { connect } from "react-redux";
import Slogan from "./components";
import i18next from "i18next";
const { language, t: translate } = i18next;

const mapStateToProps = (state) => ({
  translate,
  currentLanguage: language,
  dbUser: state.user.dbUser,
});

export default connect(mapStateToProps)(Slogan);
