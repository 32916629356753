import { connect } from "react-redux";
import {
  getBookLanguages,
  getBookSeries,
  getBookTopics,
} from "../../../actions";
import TopicBalloonList from "./components";

const mapDispatchToProps = (dispatch) => ({
  loadFilters: () => {
    dispatch(getBookSeries());
    dispatch(getBookTopics());
    dispatch(getBookLanguages());
  },
});

const mapStateToProps = (state) => ({
  bookTopics: state.books.topics,
  bookSeries: state.books.series,
});

export default connect(mapStateToProps, mapDispatchToProps)(TopicBalloonList);
