import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import ImgManaged from "../../../../components/common/img-managed";
import { useTranslation } from "react-i18next";

import "./style.css";

const CourseCard = (props) => {
  const { t } = useTranslation();
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const image =
    process.env.REACT_APP_API_URL +
    "/images/covers/courses/" +
    props.course.code +
    ".jpg";

  const course = props.course;

  function toggle() {
    setDropdownOpen(!dropdownOpen);
  }

  function getBookLink({ fileSuffix, format, name }, code, i) {
    const suffix = !!fileSuffix ? `-${fileSuffix}` : "";
    return (
      <a
        key={i}
        href={`${process.env.REACT_APP_API_URL}/${format}/courses/${code}${suffix}.${format}`}
        target="_blank"
        rel="noopener"
        className="btn"
      >
        {name}
      </a>
    );
  }

  function getBooks({ courseBooks, code }) {
    const references = courseBooks.filter((r) => !r.isAnswerBook).reverse();
    const answers = courseBooks.filter((r) => r.isAnswerBook);

    const referencesList = references.map((book, i) =>
      getBookLink(book, code, i)
    );
    const answersList = answers.map((book, i) => getBookLink(book, code, i));

    const referenceSection =
      references.length > 0 ? <div>{referencesList}</div> : null;

    const answerSection = answers.length > 0 ? <div>{answersList}</div> : null;

    return (
      <div>
        {referenceSection}
        {answerSection}
      </div>
    );
  }

  return (
    <div className={"title-container d-flex flex-row"}>
      <Link to={"/course/" + course.code} style={{ textDecoration: "none" }}>
        <div className="d-flex flex-row">
          <ImgManaged src={image} type={"Booklets"} width={100} />
          <div className="p-2">
            <div className="title-description">
              <p>{course.title}</p>
            </div>
            <p className="authors">{course.author.name}</p>
          </div>
        </div>
      </Link>
      <div className="d-flex flex-row button-container">
        <Dropdown
          isOpen={dropdownOpen}
          toggle={toggle}
          style={{ display: "block" }}
        >
          <DropdownToggle caret className="btn-light dropdown-toggle">
            <i className="fa fa-arrow-down fa-3" aria-hidden="true"></i>
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem header>{t("download-as")}:</DropdownItem>
            {getBooks(course)}
          </DropdownMenu>
        </Dropdown>
      </div>
    </div>
  );
};

export default CourseCard;
