import { connect } from "react-redux";
import Featured from "./components/";
import { getNovelties } from "../../../../actions";

const mapStateToProps = (state) => ({
  browserFilter: state.options.browserFilter,
  getNovelties: () => getNovelties(state),
});

export default connect(mapStateToProps)(Featured);
