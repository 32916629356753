import React from "react";
import { Link } from "react-router-dom";
import { Flags } from "../../../common/flags";

const LanguageAvailable = (book, translate) => (
  <div className="language-available">
    <span className="bold">{translate("languages-available")}:</span>
    <ul>
      {book.children.map((child, i) => (
        <li key={i}>
          <Link
            to={`/book/${child.code}/${child.friendlyTitle}`}
            className="btn"
          >
            <abbr title={translate("language-" + child.language)}>
              <img src={Flags[child.language]} alt={child.code}></img>
            </abbr>
            <span>{child.title}</span>
          </Link>
        </li>
      ))}
    </ul>
  </div>
);

export default LanguageAvailable;
