import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getBookTopicsByBookCode } from "../../../actions";
import { Link } from "react-router-dom";

const BookTopicList = ({ bookCode }) => {
  const [loading, setLoading] = useState(true);
  const [topics, setTopics] = useState([]);
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language?.toLowerCase().substring(0, 2);

  useEffect(() => {
    loadData();
  }, []);

  function loadData() {
    getBookTopicsByBookCode(bookCode)
      .then((data) => {
        setTopics(data);
        setLoading(false);
      })
      .catch((e) => setLoading(false));
  }

  if (loading || topics.length < 1) return null;
  return (
    <div className="mt-5">
      <div className="bold">{t("topics")}:</div>
      <div className="">
        {topics.map((t) => {
          return (
            <div className="d-inline-flex p-2" key={t.id}>
              <Link
                to={`/literature?sortby=creationdate&language=${currentLanguage}&topicId=${t.id}&serieId=0&reload=true`}
                className="btn btn-outline-secondary"
              >
                {t.name}
              </Link>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default BookTopicList;
