import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { CHAPEL_LIBRARY } from "../../../common/constants";
import { useTranslation } from "react-i18next";

const ThankYou = () => {
  const { t } = useTranslation();
  return (
    <div className="container text-relevant text-justify">
      <Helmet>
        <title>
          {t("thank-you")} - {CHAPEL_LIBRARY}
        </title>
        <meta name="description" content="" />
      </Helmet>
      <h1>{t("thank-you")}</h1>
      <p>{t("thank-you-p1")}</p>
      <p>
        <Link to="/about/support" className="btn btn-outline-primary">
          {t("more-information")}
        </Link>
      </p>
    </div>
  );
};

export default ThankYou;
