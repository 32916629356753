import { connect } from "react-redux";
import { enableBrowserFilter } from "../../../../../../actions";

import BrowserLanguageFilter from "./components";

const mapDispatchToProps = (dispatch) => ({
  enableBrowserFilter: (enabled) => dispatch(enableBrowserFilter(enabled)),
});

const mapStateToProps = (state) => ({
  browserFilter: state.options.browserFilter,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BrowserLanguageFilter);
