import React from "react";
import { useTranslation } from "react-i18next";

const CheckoutCompleteSummary = (props) => {
  const { t } = useTranslation();
  return (
    <div className="text-relevant">
      <h2>{t("thank-you")}</h2>
      {props.lastOrder.orderDetails &&
        !!props.lastOrder.orderDetails.length && (
          <p>{t("cart-posted-explanation")}</p>
        )}
      {props.lastOrder.donation > 0 && <p>{t("thank-you-p1")}</p>}
      {props.lastOrder.addressString && (
        <p>
          <strong>{t("address")}:</strong> {props.lastOrder.addressString}
        </p>
      )}
      {props.lastOrder.valueFree > 0 && (
        <p>
          <strong>
            {t("value")} {t("free")}:
          </strong>{" "}
          ${(props.lastOrder.valueFree + 0).toFixed(2)}
        </p>
      )}
      {props.lastOrder.valuePaid > 0 && (
        <p>
          <strong>
            {t("value")} {t("pay")}:
          </strong>{" "}
          ${props.lastOrder.valuePaid.toFixed(2)}
        </p>
      )}
      {props.lastOrder.donation > 0 && (
        <p>
          <strong>{t("donations")}:</strong> $
          {props.lastOrder.donation.toFixed(2)}
        </p>
      )}
      {props.lastOrder.description && (
        <p>
          <strong>{t("frequency-monthly-details")}</strong>{" "}
          {props.lastOrder.description}
        </p>
      )}
      {props.lastOrder.paymentId && (
        <p>
          <strong>{t("transaction-id")}:</strong> {props.lastOrder.paymentId}
          <br />
          <small>
            Powered by{" "}
            <a href="https://cornerstonepaymentsystems.com/">
              Cornerstone Payment Systems
            </a>
            .
          </small>
        </p>
      )}
      {props.lastOrder.orderDetails && !!props.lastOrder.orderDetails.length && (
        <>
          <br />
          <p>
            <strong>{t("books")}:</strong>
          </p>
          <ul>
            {" "}
            {props.lastOrder.orderDetails.map((item, i) => (
              <li key={i}>
                {item.code} <strong>{item.title}</strong> $
                {(Number(item.price) + 0).toFixed(2)} X {item.quantity}
              </li>
            ))}
          </ul>
        </>
      )}
    </div>
  );
};

export default CheckoutCompleteSummary;
