import React, { Component } from "react";
import { Alert } from "reactstrap";

class Message extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.message.timestamp !== nextProps.message.timestamp) {
      this.setState({ visible: true });
      setTimeout(() => {
        this.setState({ visible: false });
      }, this.props.leaveTimeOut);
    }
  }

  onDismiss = () => {
    this.setState({ visible: false });
  };

  render() {
    return (
      <Alert
        color={this.props.message.type}
        isOpen={this.state.visible}
        toggle={this.onDismiss}
        className="fixed"
        style={{
          position: "fixed",
          top: "70px",
          right: "10px",
          zIndex: "100",
        }}
      >
        {!!this.props.message.header ? (
          <h1>{this.props.message.header}</h1>
        ) : (
          ""
        )}
        {!!this.props.message.text ? <p>{this.props.message.text}</p> : ""}
      </Alert>
    );
  }
}

export default Message;
