import React, { useEffect, useState, Fragment } from "react";
import { Link } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import useAuthStatus from "../../../../../../../common/auth-status";
import { Collapse, Button, CardBody, Card } from "reactstrap";
import Avatar from "../../../../../../common/avatar";
import { useTranslation } from "react-i18next";

const Slogan = (props) => {
  const { t } = useTranslation();
  const { isAuthenticated, loginWithRedirect, logout } = useAuth0();
  const [authLoading, chapelDbUserLoaded] = useAuthStatus();
  const ready = isAuthenticated && !authLoading;
  const [isOpen, setIsOpen] = useState(false);
  const [initials, setInitials] = useState("HI");
  const toggle = () => setIsOpen(!isOpen);

  const logoutWithRedirect = () => {
    logout({
      returnTo: window.location.origin,
    });
  };

  useEffect(() => {
    if (ready) {
      if (chapelDbUserLoaded) {
        setInitials(
          props.dbUser.firstName.charAt(0) + props.dbUser.lastName.charAt(0)
        );
      }
    }
  }, [ready, chapelDbUserLoaded, props.dbUser]);

  return (
    <div className="row pb-3">
      <div className="col-md-4 col-sm-12 slogan pb-3">
        <span>{t("slogan")}</span>
      </div>
      <div className="col-md-4 col-sm-12" />
      <div className="col-md-4 col-sm-12">
        {!isAuthenticated && !authLoading && (
          <a
            className="btn btn-primary col-4"
            onClick={() => loginWithRedirect({})}
          >
            {t("login")}
          </a>
        )}
        {ready && (
          <Button
            color="primary"
            onClick={toggle}
            className="col-4"
            style={{ overflow: "hidden", whiteSpace: "nowrap" }}
          >
            <Avatar initials={initials} mode="sm" />{" "}
            {props.dbUser.firstName || "Account"} {props.dbUser.lastName}
          </Button>
        )}

        <Link className="btn btn-primary col-4" to="/subscriptions">
          {t("subscribe")}
        </Link>
        <Link className="btn btn-primary col-4" to="/donate">
          {t("donate")}
        </Link>
        <Collapse isOpen={isOpen}>
          <Card>
            <CardBody>
              <Link
                className="btn btn-secondary btn-sm"
                to="/account"
                onClick={toggle}
              >
                {t("account")}
              </Link>{" "}
              <button
                className="btn btn-secondary btn-sm"
                onClick={() => logoutWithRedirect()}
              >
                {t("logout")}
              </button>
            </CardBody>
          </Card>
        </Collapse>
      </div>
    </div>
  );
};

export default Slogan;
