import React from "react";
import ContentContainer from "../../../common/content-container/";
import AuthorFilterBar from "./author-filter-bar";
import AuthorHighlight from "../../author-highlight";

const AuthorsList = (props) => {
  return (
    <div>
      <AuthorFilterBar />
      <ContentContainer
        get={props.getAuthors}
        reducer={props.authors}
        content={(data, i) => (
          <AuthorHighlight
            key={data.code}
            author={data}
            className="w-100 text-nowrap overflow-hidden p-1"
          />
        )}
        containerHeight={props.authors.length > 10 ? "50vh" : "inherit"}
      />
    </div>
  );
};

export default AuthorsList;
