import React, { useEffect, useState } from "react";
import CheckoutForm from "../../common/checkout-form";
import "../styles/style.css";
import { Helmet } from "react-helmet";
import { CHAPEL_LIBRARY } from "../../../common/constants";
import { useTranslation } from "react-i18next";
import CheckoutCompleteSummary from "../../common/checkout-complete-summary";
import { Button } from "reactstrap";
import { useAuth0 } from "@auth0/auth0-react";
import Loader from "../../common/loader";
import CheckoutPaypalButton from "../../common/checkout-paypal-button";

const Donate = (props) => {
  const { t } = useTranslation();
  const [showSummary, setShowSummary] = useState(false);
  const { isAuthenticated, isLoading } = useAuth0();

  useEffect(() => {
    if (
      props.lastOrder !== undefined &&
      Object.keys(props.lastOrder).length > 0
    ) {
      setShowSummary(true);
    } else {
      setShowSummary(false);
    }
  }, [props.lastOrder]);

  return (
    <div className="cart d-flex d-row flex-wrap">
      <Helmet>
        <title>
          {t("donate")} - {CHAPEL_LIBRARY}
        </title>
        <meta name="description" content="" />
      </Helmet>

      {!isLoading && (
        <div className={"col-md-6 mx-auto"} style={{ boxSizing: "border-box" }}>
          {showSummary && (
            <>
              <CheckoutCompleteSummary lastOrder={props.lastOrder} />
              <Button onClick={() => setShowSummary(false)}>
                {t("donate") + " " + t("again")}
              </Button>
            </>
          )}
          {!showSummary && (
            <>
              {!isAuthenticated && <CheckoutPaypalButton />}
              {isAuthenticated && <CheckoutForm flavor="donate" />}
            </>
          )}
        </div>
      )}
      {isLoading && (
        <div className={"col-md-6 mx-auto"} style={{ boxSizing: "border-box" }}>
          <Loader />
        </div>
      )}
    </div>
  );
};

export default Donate;
