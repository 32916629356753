import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import "./style.css";

const NavigationList = ({ items, field, isMobileFriendly = false }) => {
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language?.toUpperCase().substring(0, 2);
  const otherField = field == "topicId" ? "serieId" : "topicId";

  const calculatedContainerStyle = isMobileFriendly
    ? {}
    : {
        paddingLeft: "8px",
        maxHeight: "50vh",
        overflowX: "hidden",
        overflowY: "scroll",
      };

  const calculatedItemStyle = isMobileFriendly
    ? { fontSize: 14 }
    : {
        fontSize: 14,
      };
  const LinkComponent = isMobileFriendly
    ? ({ href, className, style, children }) => (
        <a href={href} className={className} style={style}>
          {children}
        </a>
      )
    : Link;

  if (items.length < 1) return null;
  return (
    <div>
      <div style={calculatedContainerStyle}>
        {items.map((t) => {
          if (field === "topicId" && t.booksCount < 20) return;
          return (
            <div key={t.id} className="mb-2">
              <LinkComponent
                href={`/literature?sortby=creationdate&language=${currentLanguage}&${field}=${t.id}&${otherField}=0&reload=true`}
                to={`/literature?sortby=creationdate&language=${currentLanguage}&${field}=${t.id}&${otherField}=0&reload=true`}
                className="topic-link"
                style={calculatedItemStyle}
              >
                {t.name}{" "}
                {t.booksCount && (
                  <span style={{ fontSize: 11, color: "gray" }}>
                    {t.booksCount}
                  </span>
                )}
              </LinkComponent>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default NavigationList;
