import React, { lazy, Suspense, useState, useEffect } from "react";
import { Route, Routes, Navigate, useLocation } from "react-router-dom";

//COMPONENTS
import Footer from "./footer";
import Header from "./header";
import Message from "./message";

//SCENES
import Author from "../../../components/author";
import Book from "../../../components/book";
import Cart from "../../../components/cart";

import FileRouteHandler from "../components/file-route-handler";
import Home from "../../../components/home";
import Literature from "../../../components/literature";
import ModalHandler from "./modal-handler";
import Page404 from "../../../components/error/page404";
import Periodicals from "../../../components/periodicals";
import Search from "../../../components/search";
import Subscriptions from "../../../components/subscriptions";
import ThankYou from "../../../components/thank-you";
import PrivacyPolicy from "../../../components/privacy-policy";
import ScrollToTop from "../../common/scroll-to-top";
import Donate from "../../donate";
import RequireAuth from "./require-auth";

//LAZYLOADS
const EpubReader = lazy(() => import("../../../components/common/epub-reader"));
const About = lazy(() => import("../../../components/about"));
const Prison = lazy(() => import("../../../components/prison"));
const Course = lazy(() => import("../../../components/course"));
const Courses = lazy(() => import("../../../components/courses"));
const Account = lazy(() => import("../../../components/account"));
const AudioVideo = lazy(() => import("../../../components/audio-video"));

const App = ({ search, modal, messenger }) => {
  const [modalOn, setModalOn] = useState(false);
  const [redirectToSearch, setRedirectToSearch] = useState(false);
  const location = useLocation();

  const modalToggle = () => {
    setModalOn(!modalOn);
  };

  useEffect(() => {
    if (
      location.pathname != "/search" &&
      (search.searchLiterature.length > 0 ||
        search.searchAudio.length > 0 ||
        search.searchCourses.length > 0)
    ) {
      setRedirectToSearch(true);
    }
  }, [search.searchLiterature, search.searchAudio, search.searchCourses]);

  useEffect(() => {
    if (Object.keys(modal).length > 0) setModalOn(true);
  }, [modal]);

  useEffect(() => {
    if (redirectToSearch) {
      setRedirectToSearch(false);
    }
  }, [redirectToSearch]);

  if (redirectToSearch) {
    return <Navigate to="/search" />;
  }

  return (
    <div>
      <Suspense fallback={<div>Loading...</div>}>
        <Header />
        <Message leaveTimeOut={9000} message={messenger.operationResult} />
        <ScrollToTop>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/audio/*" element={<AudioVideo />} />
            <Route path="/about/support/thank-you" element={<ThankYou />} />
            <Route path="/about/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/about/*" element={<About />} />
            <Route element={<RequireAuth />}>
              <Route path="/account" element={<Account />} />
              <Route path="/cart" element={<Cart />} />
            </Route>
            <Route path="/book/:id/*" element={<Book />} />
            <Route path="/author/:id/*" element={<Author />} />
            <Route path="/authors/:id/*" element={<Author />} />
            <Route path="/bible-institute/*" element={<Courses />} />
            <Route path="/donate" element={<Donate />} />
            <Route path="/course/:id/*" element={<Course />} />
            <Route path="/courses/*" element={<Courses />} />
            <Route path="/files/*" element={<FileRouteHandler />} />
            <Route
              path="/literature/bunyan"
              element={<Navigate to="/author/bunyanjohn" />}
            />
            <Route path="/literature/*" element={<Literature />} />
            <Route path="/prison" element={<Prison />} />
            <Route path="/read/:code" element={<EpubReader />} />
            <Route path="/literature/epub-reader" element={<EpubReader />} />
            <Route path="/periodicals/*" element={<Periodicals />} />
            <Route path="/broadcaster" element={<Periodicals />} />
            <Route path="/studies" element={<Periodicals />} />
            <Route path="/prison-ministry" element={<Prison />} />
            <Route path="/search" element={<Search />} />
            <Route
              path="/spanish/spanish-literature"
              element={<Literature />}
            />
            <Route path="/spanish/title-catalog" element={<Literature />} />
            <Route
              path="/spanish/spanish-broadcaster"
              element={<Periodicals />}
            />
            <Route
              path="/spanish/spanish-subscribe"
              element={<Subscriptions />}
            />
            <Route path="/spanish/spanish-institute" element={<Courses />} />
            <Route
              path="/spanish/spanish-prison-ministry"
              element={<Prison />}
            />
            <Route path="/spanish/spanish-about" element={<About />} />
            <Route path="/spanish" element={<Home />} />
            <Route path="/subscriptions" element={<Subscriptions />} />
            <Route path="*" element={<Page404 />} />
          </Routes>
        </ScrollToTop>
        <Footer />
        <ModalHandler
          modalToggle={modalToggle}
          params={modal.payload}
          toogleOn={modalOn}
        />
      </Suspense>
    </div>
  );
};

export default App;
