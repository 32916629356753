import React from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

const MessageModal = (props) => {
  return (
    <div>
      <Modal
        isOpen={props.isOpen}
        toggle={props.modaltoggle}
        className={props.className}
        backdrop="static"
      >
        <ModalHeader toggle={props.modaltoggle}>
          {props.modalHeader}
        </ModalHeader>
        <ModalBody>{props.modalBody}</ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={props.modaltoggle}>
            {props.closeLabel}
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default MessageModal;
