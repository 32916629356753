export const INPUT_TYPES = {
  EMAIL: 0,
  NAME: 1,
  CURRENCY: 2,
  PHONE: 3,
};

export const AUDIO_SERIES = {
  SPURGEON: 9,
  BUILDING_CHURCHES_CONF_2013: 10,
  FAMILY_CONF_2012: 11,
  FAMILY_CONF_2011: 12,
  PASTORS_WIVES_CONF_2011: 13,
  FAMILY_CONF_2010: 14,
  FOUNDATION_DOCTRINE_CONF_2010: 15,
  FOUNDATION_DOCTRINE_CONF_2009: 16,
  FAMILY_CONF_2018: 17,
  PASSION_FOR_GOD_CONF_2018: 18,
  HERE_WE_STAND: 19,
  LECTURES_IN_PASTORAL_THEOLOGY: 32,
  CONFERENCES: 41,
  LEEROY_SHELTON: 42,
  BOOKS: 43,
};

export const MODAL_TYPES = {
  AUDIO: 1,
  MESSAGE: 2,
};

export const CHAPEL_LIBRARY = "Chapel Library";

export const AUTHORS_SORT_TYPES = {
  last_name: "LastName",
  most_titles: "MostTitles",
};

export const BOOKS_SORT_TYPES = {
  title: "Title",
  popularity: "Popularity",
  creationdate: "Creation Date",
};
