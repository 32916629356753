import React from "react";
import { Link } from "react-router-dom";
import ImgManaged from "../../../../../components/common/img-managed";

const Item = ({ target, src, alt, type, readmore }) => {
  return (
    <div className="top-downloads__item">
      <Link to={target} style={{ height: "inherit" }}>
        <ImgManaged
          className="img-thumbnail"
          src={src}
          alt={alt}
          type={type}
          width={160}
        />
      </Link>
      <div className="top-downloads__item-button">
        <Link to={target} className="btn btn-outline-primary">
          {readmore} »
        </Link>
      </div>
    </div>
  );
};

export default Item;
