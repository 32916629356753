import { connect } from "react-redux";
import {
  getBookSeries,
  getBookTopics,
  getBookLanguages,
  changeSearchCriteria,
} from "../../../actions";
import FilterBar from "./component";

const mapDispatchToProps = (dispatch) => ({
  getFilters: () => {
    dispatch(getBookSeries());
    dispatch(getBookTopics());
    dispatch(getBookLanguages());
  },
  changeSearchCriteria: (criteria, stateHandler, actionType) =>
    dispatch(changeSearchCriteria(criteria, stateHandler, actionType)),
  dispatchGet: (getFunction) => dispatch(getFunction()),
});

const mapStateToProps = (state) => ({
  bookSeries: state.books.series,
  bookTopics: state.books.topics,
  bookLanguages: state.books.languages,
  searchCriteria: state.search,
  literatureBooks: state.books.literatureBooks,
});

export default connect(mapStateToProps, mapDispatchToProps)(FilterBar);
