import { connect } from "react-redux";
import {
  getAllByCriteria,
  startMainSearch,
  endMainSearch,
} from "../../../../../../actions";

import Search from "./components";

const mapDispatchToProps = (dispatch) => ({
  getAllByCriteria: (criteria) => dispatch(getAllByCriteria(criteria)),
  startSearch: () => dispatch(startMainSearch()),
  endSearch: () => dispatch(endMainSearch()),
});

const mapStateToProps = (state) => ({
  searchQuerying: state.search.mainFilterQuerying,
});

export default connect(mapStateToProps, mapDispatchToProps)(Search);
