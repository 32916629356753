import { connect } from "react-redux";
import { getBooks, ACTION_TYPES } from "../../../actions";
import LiteratureTab from "./components";

const mapStateToProps = (state) => ({
  filterActionType: ACTION_TYPES.LITERATURE_SEARCH_CRITERIA,
  getBooks: (params) => getBooks(params),
  literatureBooks: state.books.literatureBooks,
});

export default connect(mapStateToProps)(LiteratureTab);
