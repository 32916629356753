import React, { useState, useEffect } from "react";
import Loader from "../../../components/common/loader";
import ImgManaged from "../../../components/common/img-managed";
import ContentContainer from "../../../components/common/content-container";
import BookCard from "../../../components/common/book-card";
import { useParams } from "react-router-dom";
import { CHAPEL_LIBRARY } from "../../../common/constants";

import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

const Author = (props) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [author, setAuthor] = useState(null);
  const [image, setImage] = useState("");
  const { id } = useParams();

  useEffect(() => {
    props
      .getAuthor(id)
      .then((response) => {
        setAuthor(response);
        setIsLoading(false);
        setImage(process.env.REACT_APP_API_URL + response.photo);
      })
      .catch((e) => {});
  }, [id]);

  if (isLoading) {
    return <Loader height={850} />;
  }

  return (
    <div className="container" key={id}>
      <Helmet>
        <title>{`${author.name} - ${CHAPEL_LIBRARY}`}</title>
        <meta name="description" content="" />
      </Helmet>
      <div className="row justify-content-center" style={{ marginTop: "1em" }}>
        <div
          className="col-sm-12 col-md-4"
          style={{
            textAlign: "center",
            height: "400px",
            marginBottom: "2em",
          }}
        >
          <ImgManaged src={image} alt={author.code} type="User" width={281} />
        </div>
        <div className="col-sm-12 col-md-8">
          <h1>{author.name}</h1>
          <p>
            <span className="text-justify">{author.date}</span>
          </p>
          <p
            className="text-justify"
            dangerouslySetInnerHTML={{ __html: author.biography }}
          />
        </div>
      </div>
      <h4>{t("books")}</h4>
      <ContentContainer
        get={props.getBooksByAuthor}
        params={{ code: author.code }}
        sortby={1}
        content={(data, i) => <BookCard key={data.id} book={data}></BookCard>}
      />
    </div>
  );
};

export default Author;
