import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const FileRouteHandler = (props) => {
  const { t } = useTranslation();
  const [fileFound, setFileFound] = useState("");

  useEffect(() => {
    const { location } = props;
    const bookName = location.pathname.substr(
      location.pathname.lastIndexOf("/") + 1
    );
    findFile(bookName)
      .then((data) => {
        window.location.assign(`${process.env.REACT_APP_API_URL}${data.url}`);
      })
      .catch((error) => {
        props.sendMessage(t("err-redirect-file-not-found"));
        setFileFound(null);
      });
  }, []);
  if (fileFound === null) {
    return <Navigate to="/" />;
  }

  return <div>Finding...</div>;
};

export default FileRouteHandler;
