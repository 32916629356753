import { connect } from "react-redux";
import Authenticated from "./components";
import {
  changeSubscriptionsForm,
  getCountries,
  getRegions,
  getSubcriptions,
  openModal,
  saveSubscription,
  getUserSubscriptions,
  signupPrintSubscription,
} from "../../../../../../actions";
import { MODAL_TYPES } from "../../../../../../common/constants";

const mapDispatchToProps = (dispatch) => ({
  changeSubscriptionsForm: (contact) =>
    dispatch(changeSubscriptionsForm(contact)),
  getCountries,
  getRegions,
  getSubcriptions,
  openMessageModal: (modalHeader, modalBody) => {
    dispatch(openModal(MODAL_TYPES.MESSAGE, { modalHeader, modalBody }));
  },
  saveForm: () => dispatch(saveSubscription()),
});

const mapStateToProps = (state) => ({
  form: state.subscriptions.form,
  dbUser: state.user.dbUser,
  addresses: state.user.addresses,
  getUserSubscriptions: (type) => getUserSubscriptions(type, state),
  signupPrintSubscription: (subscriptionId) =>
    signupPrintSubscription(state.user.token, subscriptionId),
});

export default connect(mapStateToProps, mapDispatchToProps)(Authenticated);
