import React from "react";
import { CardHeader, CardBody, FormGroup, Label, Input } from "reactstrap";
import { useTranslation } from "react-i18next";

const FreePaySelect = ({ form, dbUser, valInCart, setForm }) => {
  const { t } = useTranslation();
  let type = form.type;
  let color = type === "free" ? "green" : "red";
  return (
    <>
      <CardHeader style={{ color: color }}>{t("order-" + type)}</CardHeader>
      <CardBody>
        {dbUser.alreadyOrderedMo && (
          <div>
            {t("account-free-order-status")} {dbUser.lastOrderDate}
            {". "}
            {dbUser.alreadyOrderedMo && (
              <div className="alert alert-warning">
                {t("cart-already-ordered")}
              </div>
            )}
            <hr />
          </div>
        )}
        {!dbUser.alreadyOrderedMo && (
          <FormGroup check>
            <p>
              {t("this-is-" + type + "-order", {
                freeLimit: dbUser.freeLimit,
              })}
            </p>
            <p>
              <Label check>
                <Input
                  type="radio"
                  checked={type === "free"}
                  name="paymentType"
                  onChange={() => {
                    if (valInCart < dbUser.freeLimit) {
                      setForm("type", "free");
                    } else {
                      alert(
                        t("reduce-cart", {
                          freeLimit: dbUser.freeLimit,
                        })
                      );
                    }
                  }}
                />{" "}
                {t("switch-free")}
              </Label>
              <Label check>
                <Input
                  type="radio"
                  checked={type === "pay"}
                  name="paymentType"
                  onChange={() => {
                    setForm("type", "pay");
                  }}
                />{" "}
                {t("switch-pay", {
                  freeLimit: dbUser.freeLimit,
                })}
              </Label>
            </p>
          </FormGroup>
        )}
      </CardBody>
    </>
  );
};

export default FreePaySelect;
