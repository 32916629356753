import React from "react";
import { Button } from "reactstrap";

const BookArticles = ({ book, t, renderAuthors, modalToggleAudioArticle }) => {
  return book.articles.length > 0 ? (
    <div>
      <span className="bold">{t(book.number ? "articles" : "contents")}:</span>
      <ul>
        {book.articles.map((a, i) => (
          <li key={i}>
            <em>{a.title}</em>
            {book.number && (
              <span>
                {" "}
                - {renderAuthors([a.author])} - {a.description}{" "}
              </span>
            )}
            <Button
              className="btn btn-light btn-sm ml-2"
              style={{ display: a.audioUrl ? "inline-block" : "none" }}
              tag="a"
              href={process.env.REACT_APP_API_URL + a.audioUrl}
              onClick={(e) => {
                e.preventDefault();
                modalToggleAudioArticle(a);
              }}
            >
              <i className="fa fas fa-volume-up"></i>
              <span style={{ marginLeft: "1em" }}>{t("listen-online")}</span>
            </Button>
          </li>
        ))}
      </ul>
    </div>
  ) : null;
};

export default BookArticles;
