import React, { useState, useEffect } from "react";
import { Button, Form, Input, Popover, PopoverHeader } from "reactstrap";
import Spinner from "../../../../../../common/loader/components/spinner";
import LanguageSelector from "./language-selector";
import { useTranslation } from "react-i18next";
import { useSearchParams, useLocation } from "react-router-dom";

const Search = ({
  startSearch,
  getAllByCriteria,
  endSearch,
  searchQuerying,
}) => {
  const { t } = useTranslation();

  const [searchParams, setSearchParams] = useSearchParams();
  const [criteria, setCriteria] = useState("");
  const [popError, setPopError] = useState(false);
  const [initialized, setInitialized] = useState(true);
  const location = useLocation();

  useEffect(() => {
    const searchParamsObject = Object.fromEntries([...searchParams]);
    if (
      location.pathname == "/search" &&
      initialized &&
      searchParamsObject.input &&
      searchParamsObject.input.length > 2
    ) {
      getAllByCriteria(searchParamsObject.input);
    }
  }, [initialized]);

  const onSubmit = async (e) => {
    e && e.preventDefault();
    if (criteria.length > 2) {
      setPopError(false);
      startSearch();
      await getAllByCriteria(criteria);
      endSearch();
    } else {
      setPopError(true);
    }
  };

  function onChange(e) {
    setCriteria(e.target.value);
  }

  function toggle() {
    setPopError(!popError);
  }

  const icon = searchQuerying ? (
    <Spinner />
  ) : (
    <span className=" fa fa-search fa-4 " />
  );

  return (
    <Form
      onSubmit={onSubmit}
      className="form col-sm-12 col-md-7 col-lg-8 align-items-center"
    >
      <table style={{ width: "100%" }}>
        <tbody>
          <tr>
            <td>
              <Input
                id="inputSearch"
                onChange={onChange}
                className="form-control search-input"
                type="search"
                placeholder={t("search-placeholder")}
                value={criteria}
                aria-label="Search"
              />

              <Popover
                placement="bottom"
                isOpen={popError}
                target="inputSearch"
                toggle={toggle}
              >
                <PopoverHeader>{t("err-search-length")}</PopoverHeader>
              </Popover>
            </td>
            <td>
              <Button
                type="sub"
                className="btn btn-primary btn-search"
                style={{ width: "41px" }}
                aria-label={t("search")}
              >
                {icon}
              </Button>{" "}
            </td>
            <td>
              <LanguageSelector />
            </td>
          </tr>
        </tbody>
      </table>
    </Form>
  );
};

export default Search;
