import { ACTION_TYPES } from "../actions";

const initialState = {
  authors: [],
};
export default function (state = initialState, action) {
  switch (action.type) {
    case ACTION_TYPES.AUTHORS_LOADED:
      return {
        ...state,
        authors: action.payload,
      };
    case ACTION_TYPES.AUTHORS_ADDED:
      return {
        ...state,
        authors: [...state.authors, ...action.payload],
      };

    default:
      return state;
  }
}
