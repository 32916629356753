import { connect } from "react-redux";
import Notice from "./components";
import { getNotice } from "../../../../../../actions";

const mapStateToProps = (state) => ({
  notice: state.notice.notice,
});
const mapDispatchToProps = (dispatch) => ({
  getNotice: () => dispatch(getNotice()),
});
export default connect(mapStateToProps, mapDispatchToProps)(Notice);
