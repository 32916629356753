import React from "react";
import { Helmet } from "react-helmet";
import { CHAPEL_LIBRARY } from "../../../common/constants";
import { useTranslation } from "react-i18next";

const PrivacyPolicy = () => {
  const { t } = useTranslation(["policies"]);

  return (
    <div className="container text-relevant text-justify">
      <Helmet>
        <title>
          {t("policies:terms-use") + " & " + t("policies:privacy-policy")} -
          {CHAPEL_LIBRARY}
        </title>
        <meta name="description" content="" />
      </Helmet>
      <h1 className="display-4">
        {t("policies:terms-use") + " & " + t("policies:privacy-policy")}
      </h1>
      <p>{t("policies:updated")}</p>
      <h2>{t("policies:terms-use-header")}</h2>

      <div dangerouslySetInnerHTML={{ __html: t("policies:terms-use-text") }} />
      <h2>{t("policies:privacy-policy-header")}</h2>

      <div
        dangerouslySetInnerHTML={{ __html: t("policies:privacy-policy-text") }}
      />
    </div>
  );
};

export default PrivacyPolicy;
