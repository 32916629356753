import React from "react";
import { useTranslation } from "react-i18next";

const BrowserLanguageFilter = ({ enableBrowserFilter, browserFilter }) => {
  const { t } = useTranslation();

  const onLangChange = () => {
    enableBrowserFilter(!browserFilter);
  };

  return (
    <div className="checkbox">
      <label style={{ overflow: "hidden", display: "inline" }}>
        <input
          type="checkbox"
          checked={browserFilter}
          onChange={onLangChange}
        />{" "}
        {t("filter-label")}
      </label>
    </div>
  );
};

export default BrowserLanguageFilter;
