import { connect } from "react-redux";
import App from "./components/";

const mapStateToProps = ({ search, modal, messenger }) => ({
  search,
  modal,
  messenger,
});

export default connect(mapStateToProps, null)(App);
