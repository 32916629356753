import React, { useEffect, useState } from "react";
import { TabContent, TabPane, Nav, NavItem, NavLink, Row } from "reactstrap";
import LiteratureTab from "./literature-tab";
import AuthorTab from "./authors-tab";
import { getActiveTab } from "../../common/util";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { CHAPEL_LIBRARY } from "../../common/constants";

const Literature = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [activeTab, setActiveTab] = useState(null);

  function getTabs() {
    const tabs = {
      literature: {
        translationKey: "literature",
        component: <LiteratureTab {...props} />,
      },
      authors: {
        translationKey: "authors",
        component: <AuthorTab {...props} />,
      },
    };

    return tabs;
  }

  function toggle(tab) {
    if (activeTab !== tab) {
      setActiveTab(tab);
      if (tab !== "literature") navigate(`/literature/${tab}`);
      else navigate("/literature");
    }
  }

  function getNavItems(tabs) {
    return Object.keys(tabs).map((tab, i) => (
      <NavItem key={i}>
        <NavLink
          className={activeTab === tab ? "active" : ""}
          onClick={() => {
            toggle(tab);
          }}
        >
          <span>{t(tabs[tab].translationKey)}</span>
        </NavLink>
      </NavItem>
    ));
  }

  function getTabPanes(tabs) {
    return Object.keys(tabs).map((tab, i) => (
      <TabPane tabId={tab} key={i}>
        <Row>{tabs[tab].component}</Row>
      </TabPane>
    ));
  }

  const tabs = getTabs();

  useEffect(() => {
    if (!activeTab) {
      setActiveTab(getActiveTab(tabs));
    }
  }, [activeTab]);

  return (
    <div style={{ marginBottom: "3em" }}>
      <Helmet>
        <title>
          {t(activeTab)} - {CHAPEL_LIBRARY}
        </title>
        <meta name="description" content="" />
      </Helmet>
      <Nav tabs>{getNavItems(tabs)}</Nav>
      <TabContent className="container text-justify" activeTab={activeTab}>
        {getTabPanes(tabs)}
      </TabContent>
    </div>
  );
};

export default Literature;
