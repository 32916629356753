import React, { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useLocation, Outlet } from "react-router-dom";
import Loader from "../../../common/loader";

function RequireAuth() {
  const { user, loginWithRedirect, isLoading, isAuthenticated } = useAuth0();
  const location = useLocation();

  useEffect(() => {
    if (isLoading) {
      return <Loader />;
    }
    if (!user) {
      const fn = async () => {
        await loginWithRedirect({
          appState: { returnTo: location.pathname },
        });
      };
      fn();
    }
  }, [user, isLoading, isAuthenticated, loginWithRedirect]);

  return <Outlet />;
}

export default RequireAuth;
