import React from "react";
import { Link } from "react-router-dom";
import "./style.css";

const HoverImage = (props) => {
  return (
    <div className="col-sm-6 col-lg-3" style={{ textAlign: "center" }}>
      <Link
        to={props.target}
        className="btn btn-outline-primary "
        style={{ width: "100%", maxWidth: "310px" }}
      >
        {props.title}
      </Link>
      <div>
        <div className="descimage ">
          <img
            src={props.image}
            className="img-thumbnail"
            alt={props.title}
            style={{ "--aspect-ratio": "400/247" }}
          />
          <div className="overlay ">
            <div className="text text-justify ">
              {props.description}{" "}
              <Link to={props.target} className="btn btn-primary ">
                {props.readmore}»
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HoverImage;
