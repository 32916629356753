import React, { useEffect } from "react";
import NavigationList from "../../navigation-list";

const TopicNavigationList = ({
  loadTopics,
  bookTopics,
  isMobileFriendly = false,
}) => {
  useEffect(() => {
    if (bookTopics?.length < 1) loadTopics();
  }, []);

  return (
    <div>
      <NavigationList
        field={"topicId"}
        heading={"topics"}
        items={bookTopics}
        isMobileFriendly={isMobileFriendly}
      />
    </div>
  );
};

export default TopicNavigationList;
