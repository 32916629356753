import React, { Fragment, useState, useEffect } from "react";
import DropDown from "../../../../common/dropdown";
import ValidatedInput from "../../../../common/input/validated-input";
import { INPUT_TYPES } from "../../../../../common/constants";
import { useTranslation } from "react-i18next";

import { Form, FormGroup } from "reactstrap";
import Loader from "../../../../common/loader";

const EmailSubscribeForm = (props) => {
  const { t } = useTranslation();

  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedRegion, setSelectedRegion] = useState(null);
  const [countries, setCountries] = useState([]);
  const [regions, setRegions] = useState([]);
  const [validSubscriptions, setValidSubscriptions] = useState(true);
  const [validEmail, setValidEmail] = useState(true);
  const [validName, setValidName] = useState(true);
  const [validLastName, setValidLastName] = useState(true);
  const [validCountry, setValidCountry] = useState(false);
  const [validRegion, setValidRegion] = useState(false);
  const [sending, setSending] = useState(false);
  const [subcriptions, setSubscriptions] = useState([]);
  const [isPolicyAccepted, setIsPolicyAccepted] = useState(true);

  function onCountryClick({ target }) {
    setSelectedCountry(target.innerText);
    setSelectedRegion({ name: t("select"), id: null });
    setValidCountry(true);

    props.getRegions(target.value).then((regions) => {
      setRegions(regions);
    });
  }

  function onRegionClick({ target }) {
    setForm("regionId", target.value);
    setSelectedRegion({ name: target.innerText, id: target.value });
    setValidRegion(true);
  }

  const setForm = (prop, value) => {
    let form = Object.assign({}, props.form);
    form[prop] = value;
    props.changeSubscriptionsForm(form);
  };

  useEffect(() => {
    props.getCountries().then((countries) => {
      setCountries(countries);
    });
    props.getSubcriptions("digital").then((subcriptions) => {
      setSubscriptions(subcriptions);
    });
  }, []);

  function onSubscribeClicked() {
    if (validateForm()) {
      setSending(true);
      props.saveForm().then((r) => {
        setSending(false);
      });
    }
  }

  function validateForm() {
    let isValid = true;

    if (!validEmail || !props.form.email) {
      setValidEmail(false);
      isValid = false;
    }
    if (!validName || !props.form.firstName) {
      setValidName(false);
      isValid = false;
    }
    if (!validLastName || !props.form.lastName) {
      setValidLastName(false);
      isValid = false;
    }
    if (!selectedCountry) {
      setValidCountry(false);
      isValid = false;
    }
    if (!selectedRegion) {
      setValidRegion(false);
      isValid = false;
    }

    if (props.form.subscriptions.length < 1) {
      setValidSubscriptions(false);
      isValid = false;
    }

    return isValid;
  }

  function onSubscriptionChange({ target }) {
    const _subscriptions = props.form.subscriptions;

    const itemIndex = _subscriptions.indexOf(target.id);

    const result =
      itemIndex >= 0
        ? _subscriptions.filter((m) => m !== target.id)
        : _subscriptions.concat([target.id]);

    if (result.length > 0) {
      setValidSubscriptions(true);
    }

    setForm("subscriptions", [...new Set(result)]);
  }

  function renderSubcriptions() {
    return subcriptions.map((subscription, i) => {
      return (
        <div className="subscriptions-items__item" key={i}>
          <input
            id={subscription.id}
            checked={props.form.subscriptions.includes(
              subscription.id.toString()
            )}
            onChange={onSubscriptionChange}
            type="checkbox"
          />
          <label htmlFor={subscription.id}>{subscription.name}</label>
        </div>
      );
    });
  }

  function onPolicyChecked(accepted) {
    setIsPolicyAccepted(accepted);
  }

  if (sending) return <Loader />;

  return (
    <>
      {props.form && (
        <Form>
          <FormGroup>
            <h2 className="mt-3 mb-3">{t("subscription-p7")}</h2>
            <p>{t("subscription-p8")}</p>
            <div
              className="input-group-text subscriptions-items"
              style={{ whiteSpace: "pre-wrap" }}
            >
              {renderSubcriptions()}
            </div>
            <div className="invalid-feedback">
              {t("subscription-form-select-one")}.
            </div>
          </FormGroup>
          <label>{t("email")}</label>
          <ValidatedInput
            type="email"
            className="form-control col-md-4"
            errorMessage={t("input-error-message")}
            enabled={true}
            inputType={INPUT_TYPES.EMAIL}
            isValid={validEmail}
            onChange={(value) => setForm("email", value)}
            onBlur={(_, valid) => setValidEmail(valid)}
            value={props.form.email}
            placeholder={t("email")}
          />
          <div>
            <label>{t("first-name")}</label>
            <ValidatedInput
              type="text"
              className="form-control col-md-6"
              errorMessage={t("input-error-message")}
              enabled={true}
              inputType={INPUT_TYPES.NAME}
              isValid={validName}
              onBlur={(_, valid) => setValidName(valid)}
              onChange={(value) => setForm("firstName", value)}
              placeholder={t("first-name")}
              value={props.form.firstName}
            />
            <label>{t("last-name")}</label>
            <ValidatedInput
              type="text"
              className="form-control col-md-6"
              errorMessage={t("input-error-message")}
              enabled={true}
              inputType={INPUT_TYPES.NAME}
              isValid={validLastName}
              onBlur={(_, valid) => setValidLastName(valid)}
              onChange={(value) => setForm("lastName", value)}
              placeholder={t("last-name")}
              value={props.form.lastName}
            />
            <label>{t("country")}</label>
            <DropDown
              className="col-md-4"
              enabled={true}
              items={countries}
              label={selectedCountry ? selectedCountry : t("select")}
              onClick={onCountryClick}
              text="name"
              value="alpha2Code"
            />
            <label>{`${t("state")} / ${t("region")}`}</label>
            <DropDown
              className="col-md-4"
              items={regions}
              label={selectedRegion ? selectedRegion.name : t("select")}
              text="name"
              value="id"
              enabled={validCountry}
              onClick={onRegionClick}
            />

            <input
              type="checkbox"
              onChange={(e) => onPolicyChecked(e.target.checked)}
              checked={isPolicyAccepted}
            />
            {t("privacy-policy-agreedment")}
            <a
              href="/about/privacy-policy"
              target="_blank"
              className="btn btn-light btn btn-secondary"
              style={{ textDecoration: "underline" }}
            >
              {t("privacy-policy")}
            </a>

            <button
              className={`btn btn-primary col-12`}
              onClick={onSubscribeClicked}
              disabled={!isPolicyAccepted}
            >
              <span>{t("subscribe")}</span>
            </button>
          </div>
        </Form>
      )}
    </>
  );
};

export default EmailSubscribeForm;
