import React, { Fragment } from "react";
import { Collapse, Navbar, NavbarToggler } from "reactstrap";
import { Link } from "react-router-dom";

import logo from "../../../../resources/images/logo.png";
import "./styles/style.css";

//COMPONENTS
import Menu from "./components/menu";
import Slogan from "./components/slogan";
import Notice from "./components/notice";
import CartButton from "./components/cart-button";

class Header extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      isOpen: false,
    };
  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  }

  render() {
    return (
      <Fragment>
        <div className="fixed-header">
          <CartButton />
        </div>
        <div className="main-header">
          <header className="d-flex flex-row">
            <Navbar dark expand="md" className="col-12">
              <div className="navbar-brand col-12 col-md-5 col-lg-4 justify-content-center d-flex">
                <Link to="/">
                  <img
                    className="img-fluid"
                    style={{
                      width: "90%",
                      "--aspect-ratio": "274/47",
                      display: "inline",
                      maxWidth: 345,
                    }}
                    src={logo}
                    alt="Logo"
                    width="345"
                    height="59"
                  />
                </Link>
                <NavbarToggler
                  onClick={this.toggle}
                  aria-label="Show or hide"
                  className="col-centered"
                />
              </div>

              <Collapse isOpen={this.state.isOpen} navbar onClick={this.toggle}>
                <Menu />
              </Collapse>
            </Navbar>
          </header>
          <Slogan />
          <Notice />
        </div>
      </Fragment>
    );
  }
}

export default Header;
