import { connect } from "react-redux";
import CartButton from "./components/";
import {
  countBooksInCart,
  sumValueInCart,
  changeCheckoutForm,
} from "../../../../../../actions";

const mapStateToProps = ({ cart, user }) => ({
  booksInCart: countBooksInCart(cart),
  valInCart: sumValueInCart(cart),
  countTitlesInCart: cart.selectedBooks.length,
  form: cart.checkoutForm,
  cartType: cart.checkoutForm.type,
  dbUser: user.dbUser,
});

const mapDispatchToProps = (dispatch) => ({
  changeCheckoutForm: (contact) => dispatch(changeCheckoutForm(contact)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CartButton);
