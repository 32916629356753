import React from "react";
import * as Modals from "../modals";
import { MODAL_TYPES } from "../../../../common/constants";
import { useTranslation } from "react-i18next";

const ModalHandler = (props) => {
  const { t } = useTranslation();

  const renderModal = () => {
    const { params } = props;
    const closeLabel =
      params && params.params && params.params.closeLabel
        ? params.params.closeLabel
        : t("close");
    if (!!params)
      switch (params.modalType) {
        case MODAL_TYPES.AUDIO:
          return (
            <Modals.AudioModal
              closeLabel={t("close")}
              isOpen={props.toogleOn}
              modaltoggle={props.modalToggle}
              title={params.params.title}
              url={params.params.url}
            />
          );
        case MODAL_TYPES.MESSAGE:
          return (
            <Modals.MessageModal
              isOpen={props.toogleOn}
              modaltoggle={props.modalToggle}
              modalHeader={params.params.modalHeader}
              modalBody={params.params.modalBody}
              closeLabel={closeLabel}
            />
          );
        default:
          return null;
      }
    return <div></div>;
  };

  return renderModal();
};

export default ModalHandler;
