import { connect } from "react-redux";
import { getBookTopics } from "../../../actions";
import TopicNavigationList from "./components";

const mapDispatchToProps = (dispatch) => ({
  loadTopics: () => {
    dispatch(getBookTopics());
  },
});

const mapStateToProps = (state) => ({
  bookTopics: state.books.topics,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TopicNavigationList);
