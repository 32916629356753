import React from "react";

import defaultBooklets from "../../../resources/images/default_booklets.jpg";
import defaultPaperbacks from "../../../resources/images/default_paperbacks.jpg";
import defaultTracts from "../../../resources/images/default_tracts.jpg";
import defaultEbooks from "../../../resources/images/default_ebooks.jpg";
import defaultUser from "../../../resources/images/default_user.jpg";
import "./index.css";

const defaultImages = {
  Booklets: defaultBooklets,
  Paperbacks: defaultPaperbacks,
  Tracts: defaultTracts,
  Undefined: defaultEbooks,
  ebooks: defaultEbooks,
  User: defaultUser,
};

const ImgManaged = ({
  src,
  alt,
  type,
  fit,
  width,
  style = "img-thumbnail",
  height,
}) => {
  const qstring = width !== undefined ? "?width=" + width : "";
  return (
    <img
      className={style}
      src={src + qstring}
      alt={alt}
      onError={(e) => {
        return (e.target.src = defaultImages[type]);
      }}
      width={width}
      height={height}
      style={{
        height: height || "inherit",
        objectFit: fit || "contain",
        "--aspect-ratio": style === "img-thumbnail" ? "400/247" : null,
      }}
    />
  );
};

export default ImgManaged;
