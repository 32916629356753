import { connect } from "react-redux";
import UserTaxId from "./components";
import { setUserTaxId } from "../../../../actions";

const mapDispatchToProps = (dispatch) => ({
  saveUserTaxId: (token, userId, taxId) =>
    dispatch(setUserTaxId(token, userId, taxId)),
});
const mapStateToProps = (state) => ({
  dbUser: state.user.dbUser,
  token: state.user.token,
});

export default connect(mapStateToProps, mapDispatchToProps)(UserTaxId);
