import { connect } from "react-redux";
import EmailSubscribeForm from "./components";
import {
  changeSubscriptionsForm,
  getCountries,
  getRegions,
  getSubcriptions,
  saveSubscription,
} from "../../../../actions";

const mapDispatchToProps = (dispatch) => ({
  changeSubscriptionsForm: (contact) =>
    dispatch(changeSubscriptionsForm(contact)),
  getCountries,
  getRegions,
  getSubcriptions,
  saveForm: () => dispatch(saveSubscription()),
});

const mapStateToProps = (state) => ({
  form: state.subscriptions.form,
});

export default connect(mapStateToProps, mapDispatchToProps)(EmailSubscribeForm);
