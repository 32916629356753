import React, { useEffect, useState } from "react";
import { Button } from "reactstrap";
import { useTranslation } from "react-i18next";

const ContentContainer = (props) => {
  const { t } = useTranslation();
  const _items = props.reducer
    ? props.reducer.map((data, i) => props.content(data, i))
    : [];
  const [items, setItems] = useState(_items);
  const [isLoading, setIsLoading] = useState(false);
  const [pageCount, setPageCount] = useState(0);

  function loadFunc() {
    const params = {
      pageCount: pageCount,
      ...props.params,
    };
    setIsLoading(true);

    if (props.reducer) {
      props.dispatchGet(() => props.get(params));
    } else {
      props.get(params).then((r) => {
        const mappedR = r.map((data, i) => props.content(data, i));
        if (params.pageCount === 0) {
          setItems(mappedR);
        } else {
          setItems(items.concat(mappedR));
        }
        setIsLoading(false);
      });
    }
  }

  useEffect(() => {
    if (items.length < 1 && !isLoading) {
      loadFunc();
    }
  }, []);

  useEffect(() => {
    if (items.length > 0 && pageCount > 0) {
      loadFunc();
    }
  }, [pageCount]);

  useEffect(() => {
    if (!isLoading && props.params) {
      const reloadTimer = setTimeout(() => {
        setPageCount(0);
        loadFunc();
      }, 300);
      return () => clearTimeout(reloadTimer);
    }
  }, [props.params]);

  useEffect(() => {
    if (props.reducer) {
      setItems(props.reducer.map((data, i) => props.content(data, i)));
      setIsLoading(false);
    }
  }, [props.reducer]);

  function loadMore() {
    if (!isLoading) {
      setPageCount(pageCount + 1);
    }
  }

  const onScroll = (e) => {
    if (!isLoading) {
      const height =
        parseInt(e.target.scrollTop, 10) + parseInt(e.target.style.height, 10);
      const sh = e.target.scrollHeight;
      if (height >= sh && items.length > 0) {
        setPageCount(pageCount + 1);
      }
    }
  };

  const loader = isLoading ? <div className="loader"></div> : null;

  const button =
    items.length > 9 ? (
      <Button
        onClick={() => {
          loadMore();
        }}
        className="btn"
        style={{ display: !isLoading ? "inline-block" : "none" }}
      >
        {t("load-more")}
      </Button>
    ) : null;

  return (
    <div className="container">
      <div
        style={{
          height: props.containerHeight || "500px",
          overflow: "scroll",
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
          overflowX: "hidden",
        }}
        onScroll={onScroll}
      >
        {items}
        {button}
      </div>
      {loader}
    </div>
  );
};

export default ContentContainer;
