import React, { useState } from "react";
import { Flags } from "../../../../../../../common/flags";
import { useTranslation } from "react-i18next";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import BrowserLanguageFilter from "../../browser-language-filter";

const LanguageSelector = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen(!dropdownOpen);

  const { i18n, t } = useTranslation();

  const currentLang = i18n.language.substring(0, 2);
  const currentLangUp = currentLang.toUpperCase();
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };
  const langs = i18n.languages;

  return (
    <Dropdown isOpen={dropdownOpen} toggle={toggle}>
      <DropdownToggle caret color="primary">
        <img
          src={Flags[currentLangUp]}
          alt={t("language-" + currentLangUp)}
          width="16"
          height="16"
        ></img>{" "}
      </DropdownToggle>
      <DropdownMenu>
        <DropdownItem header>{t("languages-available")}</DropdownItem>
        {langs.map((lang, i) => (
          <DropdownItem
            key={i}
            onClick={() => {
              changeLanguage(lang);
            }}
          >
            <img
              src={Flags[lang.toUpperCase()]}
              alt={t("language-" + lang.toUpperCase())}
              lang={lang}
              width="16"
              height="16"
            ></img>
            <span className="ml-3">{t("language-" + lang.toUpperCase())}</span>
          </DropdownItem>
        ))}
        <DropdownItem divider />
        <DropdownItem>
          <BrowserLanguageFilter />
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
};

export default LanguageSelector;
