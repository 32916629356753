import { connect } from "react-redux";
import Author from "./components";
import { getAuthor, getBooksByAuthor } from "../../actions";

const mapStateToProps = (state) => ({
  getAuthor: (code) => getAuthor(code, state),
  getBooksByAuthor: (params) => getBooksByAuthor(params, state),
});

export default connect(mapStateToProps)(Author);
