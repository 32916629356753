import React, { useState } from "react";
import ContentContainer from "../../../../components/common/content-container";
import BookCard from "../../../../components/common/book-card";
import {
  ButtonGroup,
  Button,
  ButtonToolbar,
  InputGroup,
  Input,
} from "reactstrap";
import { useTranslation } from "react-i18next";

const BookContainerSearchable = (props) => {
  const { t } = useTranslation();
  const [currentSearch, setCurrentSearch] = useState("");
  const [containerViewMode, setContainerViewMode] = useState("cards");
  const [resultsLanguage, setResultsLanguage] = useState("");

  function onSearchInputChange(e) {
    setCurrentSearch(e.target.value);
  }
  function onLanguageChange(e) {
    setResultsLanguage(e.target.value);
  }
  function onContainerViewModeChange(e) {
    setContainerViewMode(e.target.value);
  }
  const params = { titleHint: currentSearch, language: resultsLanguage };
  return (
    <div className={`book-container-searchable ${props.className}`}>
      <div className="book-container-searchable__title">
        <h4>{t(props.title)}</h4>
        <ButtonToolbar style={{ marginLeft: "10px" }}>
          <InputGroup>
            <input
              type="text"
              className="form-control search-input"
              value={currentSearch}
              onChange={onSearchInputChange}
              placeholder={t("search-placeholder")}
            ></input>

            <ButtonGroup>
              <Button
                active={containerViewMode === "list"}
                onClick={onContainerViewModeChange}
                value="list"
              >
                {t("list-view")}
              </Button>
              <Button
                active={containerViewMode === "cards"}
                onClick={onContainerViewModeChange}
                value="cards"
              >
                {t("cards-view")}
              </Button>
            </ButtonGroup>
            {props.langSelector === "true" && (
              <Input type="select" name="language" onChange={onLanguageChange}>
                <option value="">{t("language")}</option>
                <option value="EN">{t("language-EN")}</option>
                <option value="ES">{t("language-ES")}</option>
              </Input>
            )}
          </InputGroup>
        </ButtonToolbar>
      </div>
      <div>
        <ContentContainer
          get={props.get}
          params={params}
          content={(data, i) => (
            <BookCard
              key={data.id}
              book={data}
              mode={containerViewMode}
            ></BookCard>
          )}
        />
      </div>
    </div>
  );
};

export default BookContainerSearchable;
