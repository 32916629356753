import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { CHAPEL_LIBRARY } from "../../common/constants";
import { useTranslation } from "react-i18next";

const Page404 = () => {
  const { t } = useTranslation();
  return (
    <div style={{ textAlign: "center", paddingTop: "40px" }}>
      <Helmet>
        <title>
          {"Not found"} - {CHAPEL_LIBRARY}
        </title>
        <meta name="description" content="" />
      </Helmet>
      <h1>{t("err-404")}</h1>
      <p>
        <Link to="/literature" className="btn btn-primary">
          {t("literature")}
        </Link>{" "}
      </p>
    </div>
  );
};

export default Page404;
