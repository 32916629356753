import React from "react";
import useAuthStatus from "../../../../common/auth-status";
import Authenticated from "./components/authenticated";
import NotAuthenticated from "./components/not-authenticated";

const PrintSubscribeForm = () => {
  const [authLoading, chapelDbUserLoaded] = useAuthStatus();

  if (!authLoading && chapelDbUserLoaded) return <Authenticated />;

  return <NotAuthenticated />;
};

export default PrintSubscribeForm;
