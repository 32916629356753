import React from "react";
import DropDown from "../../../../../common/dropdown";

const Sorter = ({ sorts }) => {
  return (
    <DropDown
      enabled={true}
      items={sorts.items}
      label={sorts.items.find((s) => s.selected)?.value}
      text="text"
      value="value"
      onClick={(self) => sorts.onClick(self)}
      isValid
    />
  );
};

export default Sorter;
