//put functions here that are used in more than one place in the app

import { INPUT_TYPES } from "./constants";
//import { createBrowserHistory } from "history";
import { createBrowserHistory } from "@remix-run/router";

export const getDownloadUrl = (code, format) => {
  var baseUrl = process.env.REACT_APP_API_URL;
  var url = `${baseUrl}/api/books/download?code=${code}&format=${format}`;
  return url;
};

export const redirectToBook = (url) => {
  window.open(url, "_blank");
};

export const uniqBy = (a, key) => {
  var seen = new Set();
  return a.filter((item) => {
    var k = key(item);
    return seen.has(k) ? false : seen.add(k);
  });
};

const isValidEmail = (input) => {
  //eslint-disable-next-line
  const regex =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regex.test(String(input).toLowerCase());
};

const isValidName = (input) => {
  const regex = /^\D+$/;
  return regex.test(String(input).toLowerCase());
};
const isValidCurrency = (input) => {
  const regex = /^$|^(\d*\.)?\d+$/;
  return regex.test(String(input));
};

const isValidPhone = (input) => {
  const regex = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/;
  return regex.test(String(input));
};

export const validateInput = (input, inputType) => {
  switch (inputType) {
    case INPUT_TYPES.EMAIL:
      return isValidEmail(input);
    case INPUT_TYPES.NAME:
      return isValidName(input);
    case INPUT_TYPES.CURRENCY:
      return isValidCurrency(input);
    case INPUT_TYPES.PHONE:
      return isValidPhone(input);
    default:
      return true;
  }
};

export const getBookUrl = (book) =>
  `/book/${book.code}/${book.friendlyTitle}${
    book.authors.length === 1 ? "-" + book.authors[0].code : ""
  }`;

export const getLastSegmentUrl = () => {
  const parts = window.location.pathname.split("/");
  return parts.pop() || parts.pop();
};

export const getActiveTab = (tabs) => {
  const segment = getLastSegmentUrl();

  const tabFound = Object.keys(tabs).find((item) => {
    return item === segment;
  });

  return tabFound || Object.keys(tabs)[0];
};

export const setCookie = (name, value, days) => {
  let expires = "";
  if (days) {
    let date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + (value || "") + expires + "; path=/";
};
export const getCookie = (name) => {
  const nameEQ = name + "=";
  const ca = document.cookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];

    while (c.charAt(0) === " ") {
      c = c.substring(1, c.length);
    }
    if (c.indexOf(nameEQ) === 0) {
      return c.substring(nameEQ.length, c.length);
    }
  }
  return null;
};

export const history = createBrowserHistory();
