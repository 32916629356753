import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { CHAPEL_LIBRARY } from "../../common/constants";
import "./styles/style.css";
import EmailSubscribeForm from "./components/email-subscribe-form";
import { Row, Col } from "reactstrap";
import PrintSubscribeForm from "./components/print-subscribe-form";

const Subscriptions = () => {
  const { t } = useTranslation();

  return (
    <div className="container text-relevant subscriptions">
      <Helmet>
        <title>
          {t("subscribe")} - {CHAPEL_LIBRARY}
        </title>
        <meta name="description" content="" />
      </Helmet>
      <h1>{t("subscribe")}</h1>

      <Row>
        <Col md="6" className="mb-2 mt-2 border-right p-2">
          <EmailSubscribeForm />
        </Col>
        <Col md="6" className="mb-2 mt-2 p-2">
          <PrintSubscribeForm />
        </Col>
      </Row>
    </div>
  );
};

export default Subscriptions;
