import { connect } from "react-redux";
import { getTopDownloads } from "../../../../actions";

import TopDownloadsContainer from "./components";

const mapStateToProps = (state) => ({
  browserFilter: state.options.browserFilter,
  getInfo: () => getTopDownloads(state),
});

export default connect(mapStateToProps)(TopDownloadsContainer);
