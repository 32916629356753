import { connect } from "react-redux";
import AddressForm from "./components";
import {
  changeNewAddressForm,
  getCountries,
  getRegions,
  saveUserAddress,
  openModal,
} from "../../../../actions";
import { MODAL_TYPES } from "../../../../common/constants";

const mapDispatchToProps = (dispatch) => ({
  changeNewAddressForm: (contact) => dispatch(changeNewAddressForm(contact)),
  getCountries,
  getRegions,
  saveForm: (token) => dispatch(saveUserAddress(token)),
  openMessageModal: (modalHeader, modalBody) => {
    dispatch(openModal(MODAL_TYPES.MESSAGE, { modalHeader, modalBody }));
  },
});

const mapStateToProps = (state) => ({
  form: state.user.newAddress,
  token: state.user.token,
  dbUser: state.user.dbUser,
});

export default connect(mapStateToProps, mapDispatchToProps)(AddressForm);
