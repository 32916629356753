import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button,
} from "reactstrap";
import ImgManaged from "../../../../components/common/img-managed";
import { getBookUrl, uniqBy, getDownloadUrl } from "../../../../common/util";
import BookLocales from "./book-locales";
import "./style.css";

import { useTranslation } from "react-i18next";

const BookCard = (props) => {
  const { t } = useTranslation();
  const bookTypes = ["Pdf", "Epub", "Mobi"];
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const image = process.env.REACT_APP_API_URL + props.book.coverUrl;

  const addBook = (book) => {
    props.addBookToCart(book);
  };

  function toggle() {
    setDropdownOpen(!dropdownOpen);
  }

  function modaltoggle(book) {
    let url = process.env.REACT_APP_API_URL + book.audioUrl;
    props.openAudioModal(url, book.title);
  }

  function renderAuthors(authors) {
    const uniq = uniqBy(authors, JSON.stringify);
    const result = uniq.map((a) => a.name);
    return result.join(", ");
  }

  function renderBookTypes(book) {
    return bookTypes.map((bookType, i) => {
      const faName = bookType === "Epub" ? "fa-book" : "fa-file-pdf-o";
      return (
        <DropdownItem
          key={i}
          tag="a"
          href={getDownloadUrl(book.code, bookType.toLowerCase())}
          style={{
            display: book[bookType.toLowerCase() + "Url"] ? "block" : "none",
          }}
          target="_blank"
          rel="noopener"
        >
          <i className={"fa " + faName + " fa-3"} aria-hidden="true"></i>
          <span>{bookType}</span>
        </DropdownItem>
      );
    });
  }

  const book = props.book;
  const viewMode = props.mode;
  const ddlVisible = !!book.pdfUrl || !!book.mobiUrl || !!book.epubUrl;

  const fgbStringText = (function (b) {
    switch (b) {
      case "EN":
        return "FGB";
      case "ES":
        return "Portavoz";
      case "FR":
        return "FGB";
      default:
        return "FGB";
    }
  })(book.language);

  const fgbString = !!book.number
    ? fgbStringText + " " + book.number + " - "
    : "";

  return (
    <div
      className={
        (viewMode === "list"
          ? "book-title-container-tiny"
          : "book-title-container") + " d-flex flex-row"
      }
    >
      <Link to={getBookUrl(book)} style={{ textDecoration: "none" }}>
        <div className="d-flex flex-row">
          <ImgManaged
            src={image}
            type={book.type}
            width={100}
            alt={book.title}
          />
          <div className="p-2">
            <div className="title-description">
              <p>{fgbString + book.title}</p>
            </div>
            <p className="authors">{renderAuthors(book.authors)}</p>
            {BookLocales(book, t)}
          </div>
        </div>
      </Link>
      <div className="d-flex flex-row button-container">
        <Dropdown
          isOpen={dropdownOpen}
          toggle={toggle}
          style={{ display: ddlVisible ? "block" : "none" }}
        >
          <DropdownToggle caret className="btn-light dropdown-toggle">
            <i className="fa fa-arrow-down fa-3" aria-hidden="true"></i>
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem header>{t("download-as")}:</DropdownItem>
            {renderBookTypes(book)}
          </DropdownMenu>
        </Dropdown>
        <Link
          to={`/read/${book.code}`}
          className="btn btn-light"
          style={{ display: book.epubUrl ? "block" : "none" }}
        >
          <i className="fa fa-desktop fa-3" aria-hidden="true"></i>
        </Link>
        <Button
          tag="a"
          href={process.env.REACT_APP_API_URL + book.audioUrl}
          className="btn btn-light"
          style={{ display: book.audioUrl ? "block" : "none" }}
          onClick={(e) => {
            e.preventDefault();
            modaltoggle(book);
          }}
        >
          <i className="fa fas fa-volume-up"></i>
        </Button>
        <Button
          className="btn btn-light"
          style={{ display: book.hasPrintableVersion ? "block" : "none" }}
          onClick={() => addBook(book)}
        >
          <i className="fa fas fa-shopping-cart"></i>
        </Button>
      </div>
    </div>
  );
};

export default BookCard;
