import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const NotAuthenticated = () => {
  const { t } = useTranslation();

  return (
    <>
      <h2 className="mt-3 mb-3">{t("subscription-p1")}</h2>
      <p className="alert alert-info">{t("subscriptions-print-not-auth")}</p>
      <div className="alert alert-warning">
        <span className="title">{t("subscription-p3")}</span>
        <br />
        <span>{t("subscription-p4")}</span>{" "}
        <Link to="/about/distributors" className="btn btn-outline-primary">
          {t("subscription-p5")}
        </Link>{" "}
        <span>{t("subscription-p6")}</span>
      </div>
    </>
  );
};

export default NotAuthenticated;
