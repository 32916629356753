import { connect } from "react-redux";
import UserForm from "./components";
import { changeUserForm, saveUser } from "../../../../actions";

function mapDispatchToProps(dispatch) {
  return {
    changeUserForm: (user) => dispatch(changeUserForm(user)),
    saveForm: (token) => dispatch(saveUser(token)),
  };
}

const mapStateToProps = (state) => ({
  dbUser: state.user.dbUser,
  form: state.user.userForm,
  token: state.user.token,
});

export default connect(mapStateToProps, mapDispatchToProps)(UserForm);
