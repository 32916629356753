import { connect } from "react-redux";
import Results from "./results";
import { ACTION_TYPES, getBooksByCriteria } from "../../../actions";

const mapStateToProps = (state) => ({
  filterActionType: ACTION_TYPES.MAIN_SEARCH_CRITERIA,
  getBooks: (criteria) => getBooksByCriteria(criteria),
  searchString: state.search.mainFilter.input,
  searchQuerying: state.search.mainFilterQuerying,
});

export default connect(mapStateToProps)(Results);
