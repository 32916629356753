import React, { useEffect } from "react";
import NavigationList from "../../navigation-list";
import { useTranslation } from "react-i18next";

const TopicBalloonList = ({
  loadFilters,
  bookTopics,
  bookSeries,
  isMobileFriendly = false,
}) => {
  useEffect(() => {
    if (bookTopics?.length < 1) loadData();
  }, []);

  const { t } = useTranslation();

  function loadData() {
    loadFilters();
  }

  return (
    <div>
      <h6 className="mt-2">{t("series")}</h6>
      <NavigationList
        field={"serieId"}
        items={bookSeries}
        isMobileFriendly={isMobileFriendly}
      />
      <h6 className="mt-2">{t("topics")}</h6>
      <NavigationList
        field={"topicId"}
        items={bookTopics}
        isMobileFriendly={isMobileFriendly}
      />
    </div>
  );
};

export default TopicBalloonList;
