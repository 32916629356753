import { connect } from "react-redux";
import { findFile, sendMessage } from "../../../../actions";
import FileRouteHandler from "./components/";

const mapDispatchToProps = (dispatch) => ({
  sendMessage: (message) => dispatch(sendMessage(message, "", "danger")),
});

const mapStateToProps = () => ({
  findFile: findFile,
});

export default connect(mapStateToProps, mapDispatchToProps)(FileRouteHandler);
