import { connect } from "react-redux";
import {
  getFreeBroadCasters,
  getStudiesInTheScripturesAnnual,
  getStudiesInTheScripturesMonthly,
} from "../../actions";
import Periodicals from "./components";

const mapStateToProps = (state) => ({
  getFreeBroadCasters: (params) => getFreeBroadCasters(params, state),
  getStudiesInTheScripturesAnnual: (params) =>
    getStudiesInTheScripturesAnnual(params, state),
  getStudiesInTheScripturesMonthly: (params) =>
    getStudiesInTheScripturesMonthly(params, state),
  isBrowserFilter: state.options.browserFilter,
});

export default connect(mapStateToProps)(Periodicals);
