import React from 'react';
import {Button, Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';

const AudioModal = (props) => {
    return (
        <div>
            <Modal isOpen={props.isOpen} toggle={props.modaltoggle} className={props.className} backdrop='static'>
                <ModalHeader toggle={props.modaltoggle}>Audio: {props.title}</ModalHeader>
                <ModalBody>
                    <audio controls>
                        <source src={props.url} type="audio/mpeg" />
                        Your browser does not support the audio element.
                    </audio>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={props.modaltoggle}>{props.closeLabel}</Button>
                </ModalFooter>
            </Modal>
        </div>
    );
};

export default AudioModal;