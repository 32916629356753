import React, { useState, useEffect } from "react";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Row,
  Col,
  Button,
  Alert,
  UncontrolledAlert,
} from "reactstrap";
import { useTranslation } from "react-i18next";

const CornerstonePayForm = ({
  parentActionHandle,
  parentValidateForm,
  amount,
  selectedInterval,
}) => {
  console.log(amount);
  const { t } = useTranslation();
  const [payType, setPayType] = useState("card");
  const [sending, setSending] = useState(false);

  const toggle = (tab) => {
    if (payType !== tab) setPayType(tab);
  };
  const processOnClick = () => {
    if (validateCornerstoneForm()) {
      setSending(true);
      window.CollectJS.startPaymentRequest();
    }
  };
  const configure = () => {
    window.CollectJS.configure({
      variant: "inline",
      currency: "USD",
      styleSniffer: "false",
      callback: (token) => {
        parentActionHandle(token);
      },
      customCss: {
        padding: ".375rem .75rem",
      },
      invalidCss: {
        "background-color": "#fcc9c9",
      },
      validCss: {
        color: "#495057",
      },
      focusCss: {
        color: "#495057",
        "background-color": "#c5e2f8",
      },
      fields: {
        ccnumber: {
          placeholder: "0000 0000 0000 0000" + " " + t("card-credit"),
        },
        ccexp: {
          placeholder: "00 / 00" + " " + t("card-expiration"),
        },
        cvv: {
          placeholder: "***" + " " + t("card-code"),
        },
        checkaccount: {
          placeholder: "0000000000" + " " + t("bank-account-number"),
        },
        checkaba: {
          placeholder: "000000000" + " " + t("bank-routing-number"),
        },
        checkname: {
          placeholder: "Name on Account",
        },
      },
    });
  };
  useEffect(() => {
    const configureTimer = setTimeout(() => {
      configure();
    }, 500);
    return () => clearTimeout(configureTimer);
  }, [amount, payType, selectedInterval]);

  function validateCornerstoneForm() {
    if (payType == "card") {
      let validCardNumber =
        document.querySelector("#ccnumber .CollectJSValid") !== null;
      let validExpiration =
        document.querySelector("#ccexp .CollectJSValid") !== null;
      let validCvv = document.querySelector("#cvv .CollectJSValid") !== null;
      let invalidCvv =
        document.querySelector("#cvv .CollectJSInvalid") !== null;
      let blankOrUnsavedCvv = !validCvv && !invalidCvv;
      if (!validCardNumber) return validCardNumber;
      if (!validExpiration) return validExpiration;
      if (!validCvv) return validCvv;
      if (invalidCvv) return !invalidCvv;
      if (blankOrUnsavedCvv) return !blankOrUnsavedCvv;
    } else {
      let validCheckName =
        document.querySelector("#checkname .CollectJSValid") !== null;
      let validCheckAba =
        document.querySelector("#checkaba .CollectJSValid") !== null;
      let validCheckAccount =
        document.querySelector("#checkaccount .CollectJSValid") !== null;
      if (!validCheckName) return validCheckName;
      if (!validCheckAba) return validCheckAba;
      if (!validCheckAccount) return validCheckAccount;
    }
    return parentValidateForm();
  }

  return (
    <div>
      <Nav tabs>
        <NavItem>
          <NavLink
            className={payType === "card" ? "active" : ""}
            onClick={() => {
              toggle("card");
            }}
          >
            {t("card-credit")}
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={payType === "bank" ? "active" : ""}
            onClick={() => {
              toggle("bank");
            }}
          >
            {t("bank-account")}
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={payType} className="mt-2 mb-2">
        <TabPane tabId="card">
          <Row>
            <Col sm="12">
              <div className="mb-3">{t("card-enter-header")}</div>
              <div id="ccnumber"></div>
              <div className="row">
                <div id="ccexp" className="col-6"></div>
                <div id="cvv" className="col-6"></div>
              </div>
            </Col>
          </Row>
        </TabPane>
        <TabPane tabId="bank">
          <Row>
            <Col sm="12">
              <div className="mb-3">{t("bank-enter-header")}</div>
              <div id="checkname"></div>
              <div id="checkaba"></div>
              <div id="checkaccount"></div>
            </Col>
          </Row>
        </TabPane>
      </TabContent>
      <p dangerouslySetInnerHTML={{ __html: t("card-enter-details") }} />
      <p className="alert alert-secondary text-center">
        <strong>{t("cart-total")}</strong> ${amount}
      </p>
      {selectedInterval && selectedInterval.id == 2 && (
        <UncontrolledAlert>{t("frequency-monthly-details")}</UncontrolledAlert>
      )}
      {!sending && (
        <Button className={"btn col-12 btn-info"} onClick={processOnClick}>
          {t("complete")}
        </Button>
      )}
      {sending && (
        <Button className={"btn col-12 btn-secondary"}>{t("sending")}</Button>
      )}
    </div>
  );
};

export default CornerstonePayForm;
