import React, { useState, useEffect } from "react";
import ImgManaged from "../../common/img-managed";
import CheckoutForm from "../../common/checkout-form";
import "../styles/style.css";
import useAuthStatus from "../../../common/auth-status";
import { Button, Input } from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { CHAPEL_LIBRARY } from "../../../common/constants";
import { useTranslation } from "react-i18next";
import CheckoutCompleteSummary from "../../common/checkout-complete-summary";

const Cart = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [authLoading, chapelDbUserLoaded] = useAuthStatus();
  const [show, setShow] = useState(false);
  const [showSummary, setShowSummary] = useState(false);

  const changeQty = (event, book) => {
    let count = event.target.value || 0;
    let _count = parseInt(count, 10);
    const newBook = {
      code: book.code,
      title: book.title,
      type: book.type,
      coverUrl: book.coverUrl,
      price: book.price,
      counter: _count,
    };
    props.changeCartQty(newBook);
  };

  useEffect(() => {
    if (chapelDbUserLoaded && !authLoading) {
      setShow(true);
    }
  }, [authLoading, chapelDbUserLoaded]);
  useEffect(() => {
    if (
      props.lastOrder !== undefined &&
      Object.keys(props.lastOrder).length > 0
    ) {
      setShowSummary(true);
    } else {
      setShowSummary(false);
    }
  }, [props.lastOrder]);

  const renderBooks = () => {
    const body = props.books
      .sort((a, b) => (a.title > b.title ? 1 : -1))
      .map((book, i) => {
        var image = process.env.REACT_APP_API_URL + book.coverUrl;

        return (
          <tr key={i}>
            <td className="td-image">
              <div>
                <ImgManaged src={image} type={book.type} width={100} />
              </div>
            </td>
            <td>{book.title}</td>
            <td className="td-quantity">
              <span>
                <Input
                  invalid={book.counter < 1}
                  type="text"
                  size="5"
                  name="counter"
                  value={book.counter}
                  onChange={(e) => changeQty(e, book)}
                  className=""
                />{" "}
                ${(book.lineVal + 0).toFixed(2)}
              </span>
            </td>
            <td>
              <button
                className="btn btn-info"
                onClick={() => props.addBookToCart(book)}
              >
                <i className="fa fas fa-chevron-up"></i>
              </button>
              <button
                className="btn btn-danger"
                onClick={() => props.removeBookFromCart(book)}
              >
                <i className="fa fas fa-chevron-down"></i>
              </button>
            </td>
          </tr>
        );
      });

    return (
      <div className="text-relevant">
        <h2>{t("cart")}</h2>
        <table className="table">
          <thead>
            <tr>
              <th></th>
              <th className="th-head">{t("title")}</th>
              <th>{t("quantity")}</th>
              <th></th>
            </tr>
          </thead>
          <tbody>{body}</tbody>
        </table>
        <Button
          onClick={() => {
            props.clearCart();
            navigate("/literature");
          }}
        >
          {t("cart-clear")}
        </Button>
      </div>
    );
  };

  return (
    <div className="cart d-flex d-row flex-wrap">
      <Helmet>
        <title>
          {t("cart")} - {CHAPEL_LIBRARY}
        </title>
        <meta name="description" content="" />
      </Helmet>
      <div
        className={
          (!(!showSummary && !show) && "col-md-6 mx-auto col-sm-12") +
          "col-sm-12"
        }
        style={{ boxSizing: "border-box" }}
      >
        {showSummary && !props.areBooksAvailable && props.lastOrder.id > 0 && (
          <>
            <CheckoutCompleteSummary lastOrder={props.lastOrder} />
          </>
        )}
        {props.areBooksAvailable && show && renderBooks()}
        {!showSummary && chapelDbUserLoaded && (
          <>
            {!props.areBooksAvailable && (
              <div className="text-relevant">
                <h2>{t("user-welcome-header")}</h2>
                <p
                  dangerouslySetInnerHTML={{ __html: t("cart-empty-body") }}
                ></p>
              </div>
            )}
          </>
        )}
        {!props.areBooksAvailable && chapelDbUserLoaded && (
          <Link className="btn btn-secondary" to="/literature">
            {t("keep-shopping")}
          </Link>
        )}
      </div>
      {(!showSummary || props.areBooksAvailable) && (
        <div
          className="col-md-6 mx-auto col-sm-12"
          style={{ boxSizing: "border-box" }}
        >
          <CheckoutForm flavor="cart" />
        </div>
      )}
    </div>
  );
};

export default Cart;
