import React, { useState } from "react";
import AuthorsList from "../../../../common/authors-list";
import TopicNavigationList from "../../../../common/topic-navigation-list";
import SeriesNavigationList from "../../../../common/series-navigation-list";
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
} from "reactstrap";
import { useTranslation } from "react-i18next";
import "./style.css";

const SidebarFilters = () => {
  const { t } = useTranslation();
  const [activeId, setActiveId] = useState("3");

  const toggle = (id) => {
    if (activeId === id) {
      setActiveId("inherit");
    } else {
      setActiveId(id);
    }
  };

  return (
    <Accordion open={activeId} toggle={toggle}>
      <AccordionItem>
        <AccordionHeader targetId="3">{t("authors")}</AccordionHeader>
        <AccordionBody accordionId="3" className="section-body">
          <AuthorsList />
        </AccordionBody>
      </AccordionItem>
      <AccordionItem>
        <AccordionHeader targetId="1">{t("series")}</AccordionHeader>
        <AccordionBody accordionId="1" className="section-body">
          <SeriesNavigationList />
        </AccordionBody>
      </AccordionItem>
      <AccordionItem>
        <AccordionHeader targetId="2">{t("topics")}</AccordionHeader>
        <AccordionBody accordionId="2" className="section-body">
          <TopicNavigationList />
        </AccordionBody>
      </AccordionItem>
    </Accordion>
  );
};

export default SidebarFilters;
