import React, { useState } from "react";
import { Button, Label, Input, FormGroup, Alert } from "reactstrap";
import Loader from "../../../../../components/common/loader";
import { useTranslation } from "react-i18next";

const AddressItem = ({
  a,
  deleteAddress,
  addDefId,
  setDefAddress,
  addDefSetting,
  isCartContext,
  checkoutForm,
  dbUser,
  setForm,
}) => {
  const [addDefConfirm, setAddDefConfirm] = useState(false);
  const { t } = useTranslation();
  const isNorthAmerica = a.countryId === "US" || a.countryId === "CA";

  const tryDeleteAddress = async (id) => {
    if (window.confirm(t("delete-confirm"))) {
      await deleteAddress(id);
    }
  };
  const setPrimaryButton = (a) => {
    if (addDefId === a.id) {
      return (
        <Button size="sm" disabled color="success">
          {t("address-primary")}
          {isNorthAmerica && t("address-primary-expl")}
        </Button>
      );
    } else {
      if (addDefConfirm) {
        return (
          <>
            <Button
              size="sm"
              color="primary"
              onClick={() => {
                setAddDefConfirm(false);
                setDefAddress(a.id);
              }}
            >
              {addDefSetting && <Loader />}
              {!addDefSetting && t("yes") + "! " + t("address-set-primary")}
            </Button>
            <Button
              size="sm"
              color="warning"
              onClick={() => {
                setAddDefConfirm(false);
              }}
            >
              {t("cancel")}
            </Button>
          </>
        );
      } else {
        return (
          <Button
            size="sm"
            onClick={() => {
              setAddDefConfirm(true);
            }}
          >
            {t("address-set-primary")}
          </Button>
        );
      }
    }
  };

  const needsTaxId = !dbUser.taxId && a.countryRequiresTaxId;
  return (
    <FormGroup
      className="rounded p-2 border"
      style={
        isCartContext && checkoutForm.addressId === a.id
          ? { backgroundColor: "#F0FFF0" }
          : {}
      }
    >
      <Label check>
        {isCartContext && (
          <Input
            type="radio"
            checked={checkoutForm.addressId === a.id}
            name="address"
            onChange={() => {
              setForm("addressId", a.id);
            }}
          />
        )}{" "}
        {dbUser.firstName} {dbUser.lastName}
        <br />
        {a.organization && (
          <>
            {a.organization}
            <br />
          </>
        )}
        <>
          {a.addressLine}
          <br />
        </>
        {a.city}, {a.regionName} {a.zip}, {a.countryName}
      </Label>
      {addDefConfirm && (
        <div className="alert alert-info mt-3 mb-0">
          {t("address-set-primary")}
          {isNorthAmerica && t("address-set-primary-confirm")}
        </div>
      )}
      {needsTaxId && (
        <Alert color="danger">
          {t("cart-tax-id-required", { countryName: a.countryName })}
        </Alert>
      )}
      <FormGroup className="d-flex pt-2 pl-3 justify-content-between">
        {setPrimaryButton(a)}{" "}
        {!addDefConfirm && (
          <Button
            size="sm"
            color="danger"
            onClick={() => {
              tryDeleteAddress(a.id);
            }}
          >
            {t("delete")}
          </Button>
        )}
      </FormGroup>
    </FormGroup>
  );
};

export default AddressItem;
