import { connect } from "react-redux";
import Cart from "./components";
import {
  addBookToCart,
  removeBookFromCart,
  changeCartQty,
  clearCart,
} from "../../actions";

const mapDispatchToProps = (dispatch) => ({
  addBookToCart: (book) => dispatch(addBookToCart(book)),
  clearCart: () => dispatch(clearCart()),
  changeCartQty: (book) => dispatch(changeCartQty(book)),
  removeBookFromCart: (book) => dispatch(removeBookFromCart(book)),
});

const mapStateToProps = (state) => ({
  books: state.cart.selectedBooks.sort((a, b) => a.code > b.code),
  areBooksAvailable: state.cart.selectedBooks.length > 0,
  lastOrder: state.cart.lastOrder,
});

export default connect(mapStateToProps, mapDispatchToProps)(Cart);
