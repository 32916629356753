import { ACTION_TYPES } from "../actions";

const initialState = {
  form: {
    subscriptions: [],
  },
};
export default function (state = initialState, action) {
  switch (action.type) {
    case ACTION_TYPES.CHANGE_SUBSCRIPTIONS_FORM:
      return {
        ...state,
        form: Object.assign({}, action.payload),
      };
    case ACTION_TYPES.CLEAN_SUBSCRIPTION:
      return initialState;
    default:
      return state;
  }
}
