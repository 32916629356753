import { combineReducers } from "redux";
import search from "./search";
import modal from "./modal";
import options from "./options";
import cart from "./cart";
import books from "./books";
import messenger from "./messenger";
import notice from "./notice";
import subscriptions from "./subscriptions";
import sorts from "./sorts";
import user from "./user";
import authors from "./authors";

const rootReducer = combineReducers({
  modal,
  books,
  cart,
  messenger,
  notice,
  options,
  search,
  sorts,
  subscriptions,
  user,
  authors,
});

export default rootReducer;
