import React from "react";
import { Link } from "react-router-dom";
import ImgManaged from "../../img-managed";
import "./style.css";

const AuthorHighlight = ({ author, className }) => {
  const authorUrl =
    process.env.REACT_APP_API_URL + "/images/authors/" + author.code + ".jpg";
  return (
    <span className={"author-highlight " + className}>
      <Link to={`/author/${author.code}`}>
        <ImgManaged
          src={authorUrl}
          alt={author.name}
          type={"User"}
          width={30}
          height={30}
          style="img-avatar"
        />{" "}
        {author.name}
        {author.date && <small>{" " + author.date}</small>}
      </Link>
    </span>
  );
};

export default AuthorHighlight;
