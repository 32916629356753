import React from "react";
import ReactDOM from "react-dom";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import reducers from "./reducers";
import { loadState, saveState } from "./localStorage";
import throttle from "lodash.throttle";
import "bootstrap/dist/css/bootstrap.min.css";
import "./styles/style.css";
import App from "./components/app";
import "./languages/i18n";
import Auth0ProviderWithHistory from "./auth0-provider-with-history";

const createStoreWithMiddleware = applyMiddleware(thunk)(createStore);
const persistedState = loadState();

const store = createStoreWithMiddleware(
  reducers,
  persistedState,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

//store subscription to set localstorage for any piece of redux state we want to persist in local storage.
store.subscribe(
  throttle(() => {
    saveState({
      cart: {
        selectedBooks: store.getState().cart.selectedBooks,
        checkoutForm: {
          email: "",
          type: "free",
        },
      },
    });
  }, 1000)
);

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <Auth0ProviderWithHistory>
        <Routes>
          <Route path="*" element={<App />} />
        </Routes>
      </Auth0ProviderWithHistory>
    </BrowserRouter>
  </Provider>,
  document.getElementById("root")
);
