import flagAM from "../resources/images/AM.jpg";
import flagAR from "../resources/images/AR.jpg";
import flagCS from "../resources/images/CS.jpg";
import flagEN from "../resources/images/EN.jpg";
import flagES from "../resources/images/ES.jpg";
import flagFR from "../resources/images/FR.jpg";
import flagHI from "../resources/images/HI.jpg";
import flagHT from "../resources/images/HT.jpg";
import flagID from "../resources/images/ID.jpg";
import flagIT from "../resources/images/IT.jpg";
import flagJP from "../resources/images/JP.jpg";
import flagKO from "../resources/images/KO.jpg";
import flagMN from "../resources/images/MN.jpg";
import flagNY from "../resources/images/NY.jpg";
import flagPT from "../resources/images/PT.jpg";
import flagRO from "../resources/images/RO.jpg";
import flagRU from "../resources/images/RU.jpg";
import flagRW from "../resources/images/RW.jpg";
import flagSW from "../resources/images/SW.jpg";
import flagTA from "../resources/images/TA.jpg";
import flagTE from "../resources/images/TE.jpg";
import flagTL from "../resources/images/TL.jpg";
import flagUR from "../resources/images/UR.jpg";
import flagVI from "../resources/images/VI.jpg";
import flagXH from "../resources/images/XH.jpg";
import flagZH from "../resources/images/ZH.jpg";

export const Flags = {
  AM: flagAM,
  AR: flagAR,
  CS: flagCS,
  EN: flagEN,
  ES: flagES,
  FR: flagFR,
  HI: flagHI,
  HT: flagHT,
  ID: flagID,
  IT: flagIT,
  JP: flagJP,
  KO: flagKO,
  MN: flagMN,
  MR: flagHI,
  NY: flagNY,
  PT: flagPT,
  RO: flagRO,
  RU: flagRU,
  RW: flagRW,
  SW: flagSW,
  TA: flagTA,
  TE: flagTE,
  TL: flagTL,
  UR: flagUR,
  VI: flagVI,
  XH: flagXH,
  ZH: flagZH,
};
