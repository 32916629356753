import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Form, FormGroup, Button, Alert } from "reactstrap";
import Loader from "../../../../../../common/loader";
import AddressList from "../../../../../../account/components/address-list";

const Authenticated = (props) => {
  const { t } = useTranslation();

  const [sending, setSending] = useState(false);
  const [subcriptions, setSubscriptions] = useState([]);
  const [userSubscriptions, setUserSubscriptions] = useState([]);
  const [isNorthAmerica, setIsNorthAmerica] = useState(false);
  const [primaryAddress, setPrimaryAddress] = useState({});
  const [error, setError] = useState("");

  useEffect(() => {
    props.getSubcriptions("print").then((subcriptions) => {
      setSubscriptions(subcriptions);
    });
    props.getUserSubscriptions("print").then((usubs) => {
      setUserSubscriptions(usubs);
    });
  }, []);

  useEffect(() => {
    if (props.dbUser && props.addresses) {
      setPrimaryAddress(
        props.addresses.filter((address) => {
          return address.id === props.dbUser.defaultAddress;
        })[0]
      );
    }
  }, [props.addresses, props.dbUser.defaultAddress]);

  useEffect(() => {
    if (primaryAddress && primaryAddress.countryId) {
      setIsNorthAmerica(
        primaryAddress.countryId === "US" || primaryAddress.countryId === "CA"
      );
    }
  }, [primaryAddress]);

  const trySignUp = (subscriptionId) => {
    setSending(true);
    props.signupPrintSubscription(subscriptionId).then((userSubscription) => {
      setUserSubscriptions(userSubscriptions.concat(userSubscription));
      setSending(false);
    });
  };
  function renderSubscriptionButton(subscription, date) {
    if (sending) {
      return (
        <Button color="primary">
          <Loader />
        </Button>
      );
    } else if (date) {
      return (
        <Button disabled color="success">
          {t("you-requested")}
          <br />
          <em>{subscription.name}</em>
          <br />
          {date}
        </Button>
      );
    } else {
      return (
        <Button
          color="primary"
          onClick={(e) => {
            e.preventDefault();
            trySignUp(subscription.id);
          }}
        >
          {subscription.name}
        </Button>
      );
    }
  }
  function renderSubcriptions() {
    return subcriptions.map((subscription, i) => {
      const matchedUserSub = userSubscriptions.filter((usub) => {
        return usub.subscriptionId === subscription.id;
      })[0];
      const date = matchedUserSub ? matchedUserSub.dateRequested : "";
      return (
        <span key={i} className="mr-3 ml-3">
          {renderSubscriptionButton(subscription, date)}
        </span>
      );
    });
  }

  return (
    <>
      {props.form && (
        <Form>
          <FormGroup>
            <h2 className="mt-3 mb-3">{t("subscription-p1")}</h2>
            {isNorthAmerica && (
              <>
                <p>{t("subscription-p2")}</p>
                <div className="d-flex justify-content-center mb-3">
                  {renderSubcriptions()}
                  {error && <Alert color="warning">{error}</Alert>}
                </div>
                <p>{t("subscriptions-manage")}</p>
              </>
            )}
            {!isNorthAmerica && (
              <Alert color="warning">
                <span>{t("subscriptions-primary-must-be-north-america")}</span>
                <br />
                <br />
                <span className="title">{t("subscription-p3")}</span>
                <br />
                <span>{t("subscription-p4")}</span>{" "}
                <Link
                  to="/about/distributors"
                  className="btn btn-outline-primary"
                >
                  {t("subscription-p5")}
                </Link>{" "}
                <span>{t("subscription-p6")}</span>
              </Alert>
            )}
          </FormGroup>
          {props.dbUser.userId && <AddressList />}
        </Form>
      )}
    </>
  );
};

export default Authenticated;
