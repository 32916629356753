import React from "react";
import { Flags } from "../../../../common/flags";

const BookLocales = (book, translate) => {
  const content = (book, key) => (
    <abbr title={translate("language-" + book.language)} key={key}>
      <img src={Flags[book.language]} alt={book.code}></img>
    </abbr>
  );

  return (
    <div className="locales">
      {content(book)}
      {book.children.map((child, i) => {
        return content(child, i);
      })}
    </div>
  );
};

export default BookLocales;
