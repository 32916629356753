import { ACTION_TYPES } from "../actions";

export default function (state = { browserFilter: true }, action) {
  switch (action.type) {
    case ACTION_TYPES.BROWSER_FILTER_CHANGE:
      return { browserFilter: action.payload };
    default:
      return state;
  }
}
