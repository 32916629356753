import React from "react";
import { Nav, NavItem } from "reactstrap";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import TopicBalloonList from "../../../../../../common/topic-balloon-list";

const Menu = (props) => {
  const { t, i18n } = useTranslation();
  const { language: currentLanguage } = i18n;

  var periodicals =
    currentLanguage === "fr" && props.browserFilter ? null : (
      <NavItem>
        <Link to="/periodicals" className="nav-link">
          {t("periodicals")}
        </Link>
      </NavItem>
    );

  var courses =
    props.currentLanguage === "fr" && props.browserFilter ? null : (
      <NavItem>
        <Link to="/courses" className="nav-link">
          {t("courses")}
        </Link>
      </NavItem>
    );

  return (
    <Nav className="ms-auto text-center" navbar>
      <NavItem>
        <Link to="/literature" className="nav-link">
          {t("literature")}
        </Link>
      </NavItem>
      {periodicals}
      {courses}
      <NavItem>
        <Link to="/audio" className="nav-link">
          {t("audio")}
        </Link>
      </NavItem>
      <NavItem>
        <Link to="/about" className="nav-link">
          {t("about")}
        </Link>
      </NavItem>
      <div className="d-md-none bg-light mb-4 rounded">
        <TopicBalloonList isMobileFriendly />
      </div>
    </Nav>
  );
};

export default Menu;
