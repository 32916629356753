import React from "react";
import { Link } from "react-router-dom";
import ImgManaged from "../../../components/common/img-managed";

import "./style.css";

const AuthorCard = ({ author }) => {
  const image = process.env.REACT_APP_API_URL + author.photo;
  return (
    <div className="author-container d-flex flex-row">
      <Link to={`/author/${author.code}`} style={{ textDecoration: "none" }}>
        <div className="image">
          <ImgManaged
            src={image}
            type={"User"}
            fit="cover"
            width={100}
            alt={author.firstName + " " + author.lastName}
          />
        </div>
        <div className="author-description">
          <p>{author.firstName}</p>
          <p>{author.lastName}</p>
        </div>
      </Link>
    </div>
  );
};

export default AuthorCard;
