import { connect } from "react-redux";
import BookCard from "./components";
import { openModal, addBookToCart } from "../../../actions";
import { redirectToBook } from "../../../common/util";
import { MODAL_TYPES } from "../../../common/constants";

const mapDispatchToProps = (dispatch) => ({
  addBookToCart: (book) => dispatch(addBookToCart(book)),
  openAudioModal: (url, title) => {
    dispatch(openModal(MODAL_TYPES.AUDIO, { url, title }));
  },
});

const mapStateToProps = () => ({
  redirect: redirectToBook,
});

export default connect(mapStateToProps, mapDispatchToProps)(BookCard);
