import React from "react";

const BookDownloadSection = ({ book, t, getDownloadUrl }) => {
  return !!book.pdfUrl ? (
    <div className="row mb-3">
      <div className="col-2 bold mr-3">{t("download-as")}: </div>
      <div className="col btn-group">
        <a
          href={getDownloadUrl(book.code, "pdf")}
          style={{ display: book.pdfUrl ? "inline-block" : "none" }}
          className="btn btn-outline-primary"
          target="_blank"
          rel="noopener"
        >
          <i className="fa fa-file-pdf-o fa-3" aria-hidden="true"></i>
          <span style={{ marginLeft: "0.5em" }}>Pdf</span>
        </a>
        <a
          href={getDownloadUrl(book.code, "epub")}
          style={{ display: book.epubUrl ? "inline-block" : "none" }}
          className="btn btn-outline-primary"
          target="_blank"
          rel="noopener"
        >
          <i className="fa fa-book fa-3" aria-hidden="true"></i>
          <span style={{ marginLeft: "0.5em" }}>ePub</span>
        </a>
        <a
          href={getDownloadUrl(book.code, "mobi")}
          style={{ display: book.mobiUrl ? "inline-block" : "none" }}
          className="btn btn-outline-primary"
          target="_blank"
          rel="noopener"
        >
          <i className="fa fa-tablet fa-3" aria-hidden="true"></i>
          <span style={{ marginLeft: "0.5em" }}>Mobi</span>
        </a>
      </div>
    </div>
  ) : null;
};

export default BookDownloadSection;
