import React, { useEffect, useState } from "react";
import DropDown from "../../../../components/common/dropdown";
import Spinner from "../../../../components/common/loader/components/spinner";
import { Collapse, Navbar, NavbarToggler } from "reactstrap";
import "../styles/style.css";
import { BOOKS_SORT_TYPES } from "../../../../common/constants";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

const FilterBar = (props) => {
  const { t, i18n } = useTranslation();
  const languageClean = i18n.language.substring(0, 2);
  const [isOpen, setIsOpen] = useState(true);
  const [searchParams, setSearchParams] = useSearchParams();

  const sortby = Object.keys(BOOKS_SORT_TYPES).map((key) => {
    return {
      name: t(key),
      id: key,
    };
  });

  function toggle() {
    setIsOpen(!isOpen);
  }

  useEffect(() => {
    if (props.stateHandler == "literatureFilter") {
      props.changeSearchCriteria(
        { sortby: "creationdate" },
        props.stateHandler,
        props.actionType
      );
      props.changeSearchCriteria(
        { language: languageClean.toUpperCase() },
        props.stateHandler,
        props.actionType
      );
      if (location.pathname == "/audio") {
        props.changeSearchCriteria(
          { serieId: "44" },
          props.stateHandler,
          props.actionType
        );
        onSearchClicked();
      }
    }
  }, []);

  useEffect(() => {
    const areFiltersLoaded = props.bookSeries.length > 0;
    if (!areFiltersLoaded) {
      props.getFilters();
    }
  }, [props.bookSeries]);

  useEffect(() => {
    const _searchParamsObject = Object.fromEntries([...searchParams]);
    const searchParamsObject = {
      ..._searchParamsObject,
      language: _searchParamsObject?.language?.toUpperCase(),
    };
    const searchParamsKeyArray = Object.keys(searchParamsObject);

    if (searchParamsKeyArray.length > 0) {
      searchParamsKeyArray.map((key) => {
        if (searchParamsObject[key]) {
          props.changeSearchCriteria(
            { [key]: searchParamsObject[key] },
            props.stateHandler,
            props.actionType
          );
        }
      });
    }
    if (
      searchParamsObject?.reload == "true" &&
      props.literatureBooks.length > 0
    ) {
      props.changeSearchCriteria(
        { reload: "false" },
        props.stateHandler,
        props.actionType
      );
      onSearchClicked();
    }
  }, []);

  useEffect(() => {
    if (Object.keys(props.searchCriteria[props.stateHandler]).length > 0) {
      setSearchParams(props.searchCriteria[props.stateHandler], {
        replace: true,
      });
    }
  }, [props.searchCriteria[props.stateHandler]]);

  const onQueryInputClick = ({ target }, field) => {
    props.changeSearchCriteria(
      { [field]: target.value },
      props.stateHandler,
      props.actionType
    );
  };

  const getFilterControls = (filters) =>
    filters.map((filter) => {
      if (filter.source.length > 0) {
        var btnStyle =
          props.searchCriteria[props.stateHandler][filter.field] &&
          props.searchCriteria[props.stateHandler][filter.field] != 0
            ? { backgroundColor: "#daffe2" }
            : {};
        var source = filter.skipSelect
          ? [...filter.source]
          : [{ id: null, name: t("all") }, ...filter.source];
        var filteredSource = source.filter(
          (r) => r.id == props.searchCriteria[props.stateHandler][filter.field]
        );

        var specifiedLabel =
          props.searchCriteria[props.stateHandler][filter.field] &&
          filteredSource.length > 0
            ? filteredSource[0].name
            : props.searchCriteria[props.stateHandler][filter.field];
        var _label =
          props.searchCriteria[props.stateHandler][filter.field] &&
          props.searchCriteria[props.stateHandler][filter.field] != 0
            ? specifiedLabel
            : filter.label;

        var labelShortened = _label.substring(0, 17);
        return (
          <DropDown
            className="col-md-12"
            btnStyle={btnStyle}
            enabled={true}
            items={source}
            label={labelShortened}
            onClick={(control) => onQueryInputClick(control, filter.field)}
            text={"name"}
            secondaryText={filter.field == "topicId" ? "booksCount" : null}
            value="id"
          />
        );
      }
      return null;
    });

  const onSearchClicked = (e = null) => {
    e?.preventDefault();

    props.dispatchGet(() => {
      return props.searchFunction(props.actionType);
    });
    if (!isOpen) toggle();
  };

  const labelAll = t("all");
  const filters = [
    {
      name: "serie",
      field: "serieId",
      source: props.bookSeries,
      label: labelAll,
    },
    {
      name: "topic",
      field: "topicId",
      source: props.bookTopics,
      label: labelAll,
    },
    {
      name: "language",
      field: "language",
      source: props.bookLanguages,
      label: labelAll,
    },
    {
      name: "sortby",
      field: "sortby",
      source: sortby,
      skipSelect: true,
      label: sortby[0].name,
    },
  ];

  var filterControls = getFilterControls(filters);

  const icon =
    props.bookSeries.length < 1 ? (
      <Spinner style={{ height: "16px", color: "white" }} />
    ) : (
      <span className=" fa fa-search fa-4 " />
    );

  const ddlSeries = filterControls[0];
  const ddlTopics = filterControls[1];
  const ddlLanguages = filterControls[2];
  const ddlSorts = filterControls[3];

  return (
    <form onSubmit={onSearchClicked} className="container">
      <Navbar expand="md" className="navbar-light">
        <NavbarToggler onClick={toggle} aria-label="Show or hide" />
        <Collapse isOpen={isOpen} navbar>
          <div className="filters col-12">
            <div className="col-6 col-md-3">
              <label>{t("search")}</label>

              <input
                className="form-control search-input mb-1"
                type="search"
                placeholder={t("search-placeholder")}
                value={props.searchCriteria[props.stateHandler].input || ""}
                aria-label="Search"
                readOnly={props.stateHandler == "mainFilter"}
                onChange={(control) => onQueryInputClick(control, "input")}
                style={
                  props.searchCriteria[props.stateHandler].input?.length > 0
                    ? { backgroundColor: "#daffe2" }
                    : {}
                }
              />
            </div>
            <div className="col-6 col-md-2">
              <label>{t("series")}</label>
              {ddlSeries}
            </div>
            <div className="col-6 col-md-2">
              <label>{t("topics")}</label>
              {ddlTopics}
            </div>
            <div className="col-6 col-md-2">
              <label>{t("language")}</label>
              {ddlLanguages}
            </div>
            <div className="col-6 col-md-2">
              <label>{t("sortby")}</label>
              {ddlSorts}
            </div>
            <div className="col-6 col-md-1">
              <label style={{ visibility: "hidden" }}>Find</label>
              <div className="col-md-12">
                <button
                  type="sub"
                  className="btn btn-primary btn-search mb-1"
                  onClick={onSearchClicked}
                >
                  {icon}
                </button>
              </div>
            </div>
          </div>
        </Collapse>
      </Navbar>
    </form>
  );
};

export default FilterBar;
