import React from "react";
import Spinner from "./spinner";
import { useTranslation } from "react-i18next";

const Loader = (props) => {
  const { t } = useTranslation();
  return (
    <div className="loading" style={{ height: props.height + "px" }}>
      <Spinner />
      <p>{props.label ? props.label : t("loading")}...</p>
    </div>
  );
};

export default Loader;
