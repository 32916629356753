import React from "react";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { useTranslation } from "react-i18next";

const DropDown = (props) => {
  const validationMessage = props.errorMessage
    ? props.errorMessage.replace("#", props.label)
    : "";
  const { t } = useTranslation();
  const _t = (text) => {
    if (props.translateItems) {
      return t(text);
    } else {
      return text;
    }
  };
  const renderItems = (items) => {
    if (!!items) {
      return items.map((item, i) => (
        <DropdownItem
          key={i}
          onClick={props.onClick}
          value={props.value ? item[props.value] : item}
          className={
            !!props.separatedIndex && i === props.separatedIndex - 1
              ? "separatedIndex"
              : ""
          }
        >
          {props.text ? _t(item[props.text]) : _t(item)}{" "}
          {!!props.secondaryText ? (
            <div style={{ fontSize: 12, color: "gray", float: "right" }}>
              {item[props.secondaryText]}
            </div>
          ) : null}
        </DropdownItem>
      ));
    }
  };

  return (
    <>
      <UncontrolledDropdown
        className={props.className}
        disabled={!props.enabled}
      >
        <DropdownToggle
          caret
          color="primary"
          disabled={!props.enabled}
          style={props.btnStyle}
        >
          {_t(props.label)}
        </DropdownToggle>
        <DropdownMenu style={{ width: "inherit" }} modifiers={props.modifiers}>
          {renderItems(props.items)}
        </DropdownMenu>
      </UncontrolledDropdown>
      {props.isValid && validationMessage && (
        <div className="invalid-feedback">{validationMessage}</div>
      )}
    </>
  );
};

export default DropDown;
