import { connect } from "react-redux";
import AudioCard from "./components";
import { openModal } from "../../../actions";
import { MODAL_TYPES } from "../../../common/constants";

const mapDispatchToProps = (dispatch) => {
  return {
    openAudioModal: (url, title) => {
      dispatch(openModal(MODAL_TYPES.AUDIO, { url, title }));
    },
  };
};

export default connect(null, mapDispatchToProps)(AudioCard);
