import React, { useEffect, useState } from "react";
import { TabContent, TabPane, Nav, NavItem, NavLink, Row } from "reactstrap";
import BookContainerSearchable from "./book-container-searchable";
import ContentContainer from "../../../components/common/content-container";
import BookCard from "../../../components/common/book-card";
import { getActiveTab } from "../../../common/util";
import { Helmet } from "react-helmet";
import { CHAPEL_LIBRARY } from "../../../common/constants";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import "../styles/style.css";

const Periodicals = (props) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [activeTab, setActiveTab] = useState(null);

  const buildStudiesInTheScriptures = () => {
    return (
      <div className="text-relevant">
        <h1>{t("studies-in-the-scriptures")}</h1>
        <h6>{t("studies-in-the-scriptures-detail")}</h6>
        <div>
          <BookContainerSearchable
            get={props.getStudiesInTheScripturesMonthly}
            translate={t}
            title="monthly"
            className="sitsm"
          />
        </div>
      </div>
    );
  };
  const buildStudiesInTheScripturesAnnual = () => {
    return (
      <div className="text-relevant">
        <h1>{t("annuals") + " - " + t("studies-in-the-scriptures")}</h1>
        <ContentContainer
          get={props.getStudiesInTheScripturesAnnual}
          content={(data, i) => <BookCard key={data.id} book={data}></BookCard>}
        />
      </div>
    );
  };

  function getTabs() {
    const tabs = {
      freegracebroadcaster: {
        translationKey: "free-grace-broadcaster",
        component: (
          <div className="text-relevant">
            <h1>{t("free-grace-broadcaster")}</h1>
            <p>{t("periodicals-fgb-p1")}</p>
            <BookContainerSearchable
              get={props.getFreeBroadCasters}
              translate={t}
              title="free-grace-broadcaster"
              className="fgb"
              langSelector="true"
            />
          </div>
        ),
      },
      studiesinthescriptures: {
        translationKey: "studies-in-the-scriptures",
        component: buildStudiesInTheScriptures(),
      },
      studiesinthescripturesannual: {
        translationKey: "annuals",
        component: buildStudiesInTheScripturesAnnual(),
      },
    };

    return tabs;
  }

  function toggle(tab) {
    if (activeTab !== tab) {
      setActiveTab(tab);
      navigate(`/periodicals/${tab}`);
    }
  }

  function getNavItems(tabs) {
    return Object.keys(tabs).map((tab, i) => (
      <NavItem key={i}>
        <NavLink
          className={activeTab === tab ? "active" : ""}
          onClick={() => {
            toggle(tab);
          }}
        >
          <span>{t(tabs[tab].translationKey)}</span>
        </NavLink>
      </NavItem>
    ));
  }

  function getTabPanes(tabs) {
    return Object.keys(tabs).map((tab, i) => (
      <TabPane tabId={tab} key={i}>
        <Row>{tabs[tab].component}</Row>
      </TabPane>
    ));
  }

  const tabs = getTabs();

  useEffect(() => {
    if (!activeTab) {
      setActiveTab(getActiveTab(tabs));
    }
  }, [activeTab]);

  return (
    <div>
      <Helmet>
        <title>
          {t("free-grace-broadcaster")} &amp; {t("studies-in-the-scriptures")} -{" "}
          {CHAPEL_LIBRARY}
        </title>
        <meta name="description" content="" />
      </Helmet>
      <div style={{ marginBottom: "3em" }}>
        <Nav tabs>{getNavItems(tabs)}</Nav>
        <TabContent className="container text-justify" activeTab={activeTab}>
          {getTabPanes(tabs)}
        </TabContent>
      </div>
    </div>
  );
};

export default Periodicals;
