import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import useAuthStatus from "../../../../../common/auth-status";
import { Button, Card, CardBody } from "reactstrap";
import ValidatedInput from "../../../../common/input/validated-input";
import { validateInput } from "../../../../../common/util";
import Avatar from "../../../../common/avatar";
import { INPUT_TYPES } from "../../../../../common/constants";
import Loader from "../../../../../components/common/loader";
import { resendVerificationEmail } from "../../../../../actions";
import { useTranslation } from "react-i18next";

const UserForm = (props) => {
  const { t } = useTranslation();
  const { isLoading, user } = useAuth0();
  const [authLoading, chapelDbUserLoaded] = useAuthStatus();
  const [formOn, setFormOn] = useState(false);
  const [validEmail, setValidEmail] = useState(true);
  const [validPhone, setValidPhone] = useState(true);
  const [validFirstName, setValidFirstName] = useState(true);
  const [validLastName, setValidLastName] = useState(true);
  const [sending, setSending] = useState(false);
  const [initials, setInitials] = useState("NA");
  const [userUsesIdProvider, setUserUsesIdProvider] = useState(false);

  useEffect(() => {
    if (!authLoading && !chapelDbUserLoaded && !isLoading) {
      setForm("email", user.email);
      setForm("externalId", user.sub);
      setFormOn(true);
    }
    if (!authLoading && chapelDbUserLoaded) {
      setFormOn(false);
    }
    if (!isLoading) {
      if (user.sub.substring(0, 5) !== "auth0") {
        setUserUsesIdProvider(true);
      }
    }
  }, [authLoading, chapelDbUserLoaded, isLoading]);

  useEffect(() => {
    if (!authLoading) {
      if (sending) {
        setSending(false);
      }
      if (chapelDbUserLoaded) {
        setInitials(
          props.dbUser.firstName.charAt(0) + props.dbUser.lastName.charAt(0)
        );
      }
    }
  }, [authLoading, props.dbUser, chapelDbUserLoaded]);

  const onSaveClick = () => {
    if (validateForm()) {
      setSending(true);
      setFormOn(false);
      props.saveForm(props.token);
    }
  };
  const setForm = (prop, value) => {
    let form = props.form;
    form[prop] = value;
    props.changeUserForm(form);
  };
  const resendVerification = () => {
    resendVerificationEmail(props.token, user.sub);
  };

  const validateForm = () => {
    let isValid = true;

    if (!validEmail || !validateInput(props.form.email, INPUT_TYPES.EMAIL)) {
      setValidEmail(false);
      isValid = false;
    }
    if (!validPhone || !validateInput(props.form.phone, INPUT_TYPES.PHONE)) {
      setValidPhone(false);
      isValid = false;
    }
    if (
      !validFirstName ||
      !validateInput(props.form.firstName, INPUT_TYPES.NAME)
    ) {
      setValidFirstName(false);
      isValid = false;
    }
    if (
      !validLastName ||
      !validateInput(props.form.lastName, INPUT_TYPES.NAME)
    ) {
      setValidLastName(false);
      isValid = false;
    }
    return isValid;
  };

  return (
    <Card style={{ marginBottom: "40px" }}>
      <CardBody>
        {user && !isLoading && !user.email_verified && (
          <>
            <h2 style={{ textAlign: "left" }}>
              {t("user-verify-email-header")}
            </h2>
            <p>{t("user-verify-email-text")}</p>
            <p>
              <Button
                onClick={() => {
                  window.location.reload(false);
                }}
                color="primary"
              >
                {t("user-verified-button")}
              </Button>
              <Button onClick={resendVerification}>
                {t("user-resend-verificaton")}
              </Button>
            </p>
          </>
        )}
        {user && user.email_verified && !isLoading && !sending && (
          <>
            {!formOn && (
              <>
                <h1 style={{ display: "flex", alignItems: "center" }}>
                  <Avatar initials={initials} /> {props.dbUser.firstName}{" "}
                  {props.dbUser.lastName}
                </h1>
                <p>
                  <b>{t("email")}:</b> {props.dbUser.email} |{" "}
                  <b>{t("phone")}:</b> {props.dbUser.phone}{" "}
                </p>
                <p>
                  <Button
                    onClick={() => {
                      setFormOn(true);
                      props.changeUserForm(props.dbUser);
                    }}
                  >
                    {t("user-change-details")}
                  </Button>
                </p>
              </>
            )}

            {formOn && (
              <>
                {chapelDbUserLoaded && (
                  <>
                    <h1 style={{ display: "flex", alignItems: "center" }}>
                      <Avatar initials={initials} /> {props.dbUser.firstName}{" "}
                      {props.dbUser.lastName}
                    </h1>

                    <p>
                      <b>{t("email")}:</b> {props.dbUser.email} |{" "}
                      <b>{t("phone")}:</b> {props.dbUser.phone}{" "}
                    </p>
                  </>
                )}
                <div className="form-group">
                  {!chapelDbUserLoaded && (
                    <>
                      <h1>{t("user-fill-form")}</h1>
                      <p>{t("user-welcome-text")}</p>
                    </>
                  )}
                  <p>{t("user-fill-form")}</p>
                  <div className="">
                    <div>
                      <label>
                        {t("first-name")}
                        <ValidatedInput
                          type="text"
                          className="col"
                          errorMessage={t("input-error-message")}
                          enabled={true}
                          inputType={INPUT_TYPES.NAME}
                          isValid={validFirstName}
                          onBlur={(_, valid) => setValidFirstName(valid)}
                          onChange={(value) => setForm("firstName", value)}
                          placeholder={t("first-name")}
                          value={props.form.firstName}
                        />
                      </label>
                      <br />
                      <label>
                        {t("last-name")}
                        <ValidatedInput
                          type="text"
                          className="col"
                          errorMessage={t("input-error-message")}
                          enabled={true}
                          inputType={INPUT_TYPES.NAME}
                          isValid={validLastName}
                          onBlur={(_, valid) => setValidLastName(valid)}
                          onChange={(value) => setForm("lastName", value)}
                          placeholder={t("last-name")}
                          value={props.form.lastName}
                        />
                      </label>
                      <br />
                      <label>
                        {t("email")}
                        <ValidatedInput
                          type="email"
                          className="col"
                          errorMessage={t("input-error-message")}
                          enabled={!userUsesIdProvider}
                          inputType={INPUT_TYPES.EMAIL}
                          isValid={validEmail}
                          onChange={(value) => setForm("email", value)}
                          onBlur={(_, valid) => setValidEmail(valid)}
                          value={props.form.email}
                          placeholder={t("email")}
                        />
                        {userUsesIdProvider && t("user-ext-id-notice")}
                      </label>
                      <br />
                      <label>
                        {t("phone")}
                        <ValidatedInput
                          type="phone"
                          className="col"
                          errorMessage={t("input-error-message")}
                          enabled={true}
                          inputType={INPUT_TYPES.PHONE}
                          isValid={validPhone}
                          onChange={(value) => setForm("phone", value)}
                          onBlur={(_, valid) => setValidPhone(valid)}
                          value={props.form.phone}
                          placeholder={t("phone")}
                        />
                      </label>
                    </div>
                    <Button
                      color="primary"
                      onMouseDown={() => {
                        validateForm();
                      }}
                      onClick={() => {
                        onSaveClick();
                      }}
                    >
                      {t("save")}
                    </Button>{" "}
                    {chapelDbUserLoaded && (
                      <Button
                        color="secondary"
                        onClick={() => {
                          setFormOn(false);
                        }}
                      >
                        {t("close")}
                      </Button>
                    )}
                  </div>
                </div>
              </>
            )}
          </>
        )}
        {(isLoading || sending) && <Loader />}
      </CardBody>
    </Card>
  );
};

export default UserForm;
