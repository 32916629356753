import { ACTION_TYPES } from "../actions";

const initialState = {
  currentBook: {},
  languages: [],
  literatureBooks: [],
  series: [],
  topics: [],
};
export default function (state = initialState, action) {
  switch (action.type) {
    case ACTION_TYPES.SELECTED_BOOK_CHANGED:
      return {
        ...state,
        currentBook: action.payload,
      };
    case ACTION_TYPES.BOOK_SERIES_LOADED:
      return {
        ...state,
        series: action.payload,
      };
    case ACTION_TYPES.BOOK_TOPICS_LOADED:
      return {
        ...state,
        topics: action.payload,
      };
    case ACTION_TYPES.BOOK_LANGUAGES_LOADED:
      return {
        ...state,
        languages: action.payload,
      };
    case ACTION_TYPES.LITERATURE_ADDED:
      return {
        ...state,
        literatureBooks: [...state.literatureBooks, ...action.payload],
      };
    case ACTION_TYPES.LITERATURE_LOADED:
      return {
        ...state,
        literatureBooks: action.payload,
      };
    default:
      return state;
  }
}
