import React, { useEffect } from "react";
import HTMLContent from "../../../../../../common/html-content";
import { useTranslation } from "react-i18next";

const Notice = ({ notice, getNotice }) => {
  const { t, i18n } = useTranslation();
  const { language: currentLanguage } = i18n;

  useEffect(() => {
    if (Object.keys(notice).length === 0) {
      getNotice();
    }
  }, [notice]);

  const noticeField =
    currentLanguage.toUpperCase().substring(0, 2) === "EN"
      ? "text"
      : "text_" + currentLanguage.toUpperCase().substring(0, 2);

  const noticeText =
    notice === undefined || !notice.text ? null : (
      <div className="alert alert-info">
        <HTMLContent content={notice[noticeField]} />
      </div>
    );

  const cookiesWarning = !navigator.cookieEnabled ? (
    <div>
      <p className="alert alert-danger">{t("cookies-off")}</p>
    </div>
  ) : null;

  const output =
    noticeText || cookiesWarning ? (
      <div className="options d-flex d-row flex-wrap">
        {noticeText}
        {cookiesWarning}
      </div>
    ) : null;

  if (output === null) {
    return null;
  } else {
    return <div>{output}</div>;
  }
};

export default Notice;
