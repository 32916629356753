import React, { useEffect, useState } from "react";

import Item from "./item";
import Loader from "../../../../../components/common/loader";
import { getBookUrl } from "../../../../../common/util";
import { useTranslation } from "react-i18next";

import "./style.css";

const TopDownloadsContainer = (props) => {
  const { t, i18n } = useTranslation();
  const { language } = i18n;
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);
  const [items, setItems] = useState([]);

  useEffect(() => {
    setIsLoading(true);
    loadData();
    setIsLoading(false);
  }, [language, props.browserFilter, isLoading]);

  function loadData() {
    props
      .getInfo()
      .then((data) => {
        setItems(data);
      })
      .catch((e) => {
        setError(e);
      });
  }

  function displayItems(items) {
    return items.map((item, i) => {
      var image = process.env.REACT_APP_API_URL + item.coverUrl;

      return (
        <Item
          key={i}
          target={getBookUrl(item)}
          src={image}
          alt={item.title}
          type={item.type}
          readmore={t("read-more")}
        />
      );
    });
  }
  if (isLoading) {
    return <Loader height={250} />;
  } else if (error) {
    return (
      <div
        style={{
          color: "red",
        }}
      >
        {error.message}
      </div>
    );
  } else if (items.length < 1) return null;

  return <div className="down-container">{displayItems(items)}</div>;
};

export default TopDownloadsContainer;
