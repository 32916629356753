import { connect } from "react-redux";
import { getBookSeries } from "../../../actions";
import SeriesNavigationList from "./components";

const mapDispatchToProps = (dispatch) => ({
  loadSeries: () => {
    dispatch(getBookSeries());
  },
});

const mapStateToProps = (state) => ({
  bookSeries: state.books.series,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SeriesNavigationList);
