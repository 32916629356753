import React from "react";
import { validateInput } from "../../../../common/util";

const ValidatedInput = (props) => {
  const onChange = (e) => {
    if (e.isTrusted) props.onChange(e.target.value);
  };

  const onBlur = ({ target }) => {
    const isValid = validateInput(target.value, props.inputType);
    if (props.onBlur) props.onBlur(target.value, isValid);
  };

  const validationMessage = !!props.errorMessage
    ? props.errorMessage.replace("#", props.placeholder)
    : "";

  return (
    <div>
      <input
        autoComplete="false"
        className={`${props.className} ${props.isValid ? "" : "is-invalid"}`}
        disabled={!props.enabled}
        name={"no" + props.type}
        onBlur={onBlur}
        onChange={onChange}
        placeholder={props.placeholder}
        required={props.required === undefined ? true : props.required}
        type={props.type}
        value={props.value || ""}
        maxLength={props.maxLength}
      />
      <div className="invalid-feedback">{validationMessage}.</div>
    </div>
  );
};

export default ValidatedInput;
