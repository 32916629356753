import { ACTION_TYPES } from "../actions";

export default function (
  state = {
    literatureFilter: {},
    authorsFilter: {},
    mainFilter: {},
    searchLiterature: [],
    searchAudio: [],
    searchCourses: [],
    mainFilterQuerying: false,
  },
  action
) {
  switch (action.type) {
    case ACTION_TYPES.SEARCH_LITERATURE_RESULTSET:
      return {
        ...state,
        searchLiterature: action.payload,
      };
    case ACTION_TYPES.SEARCH_AUDIO_RESULTSET:
      return {
        ...state,
        searchAudio: action.payload,
      };
    case ACTION_TYPES.SEARCH_COURSE_RESULTSET:
      return {
        ...state,
        searchCourses: action.payload,
      };
    case ACTION_TYPES.LITERATURE_SEARCH_CRITERIA:
      return {
        ...state,
        literatureFilter: action.payload,
      };
    case ACTION_TYPES.AUTHORS_SEARCH_CRITERIA:
      return {
        ...state,
        authorsFilter: action.payload,
      };
    case ACTION_TYPES.MAIN_SEARCH_CRITERIA:
      return {
        ...state,
        mainFilter: action.payload,
      };
    case ACTION_TYPES.MAIN_SEARCH_QUERY_START:
      return {
        ...state,
        mainFilterQuerying: true,
      };
    case ACTION_TYPES.MAIN_SEARCH_QUERY_END:
      return {
        ...state,
        mainFilterQuerying: false,
      };
    default:
      return state;
  }
}
