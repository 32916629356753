import { ACTION_TYPES } from "../actions";
import { AUTHORS_SORT_TYPES } from "../common/constants";

const initialState = {
  currentAuthorsSort: AUTHORS_SORT_TYPES.last_name,
};
export default function (state = initialState, action) {
  switch (action.type) {
    case ACTION_TYPES.SORT_AUTHOR_CHANGE:
      return {
        ...state,
        currentAuthorsSort: action.payload,
      };
    default:
      return state;
  }
}
