import { connect } from "react-redux";
import Book from "./components";
import {
  addBookToCart,
  canAddToCart,
  changeCartQty,
  changeSelectedBook,
  getBook,
  openModal,
  removeBookFromCart,
} from "../../actions";
import { MODAL_TYPES } from "../../common/constants";

const mapDispatchToProps = (dispatch) => ({
  addBookToCart: (book) => dispatch(addBookToCart(book)),
  changeSelectedBook: (book) => dispatch(changeSelectedBook(book)),
  openAudioModal: (url, title) =>
    dispatch(openModal(MODAL_TYPES.AUDIO, { url, title })),
  openMessageModal: (modalHeader, modalBody) =>
    dispatch(openModal(MODAL_TYPES.MESSAGE, { modalHeader, modalBody })),
  removeBookFromCart: (book, all) => dispatch(removeBookFromCart(book, all)),
  changeCartQty: (book) => dispatch(changeCartQty(book)),
});

const mapStateToProps = (state) => ({
  canAddToCart: canAddToCart(state.books.currentBook, state),
  currentBook: state.books.currentBook,
  getInfo: (code) => getBook(code, state),
  cartBook: (code) => state.cart.selectedBooks.find((b) => b.code === code),
  dbUser: state.user.dbUser,
});

export default connect(mapStateToProps, mapDispatchToProps)(Book);
