import React from "react";
import { Button } from "reactstrap";

import "./style.css";

const AudioCard = ({ audio, openAudioModal }) => {
  function modaltoggle(audio) {
    let url = audio.isExternal
      ? audio.audioUrl
      : process.env.REACT_APP_API_URL + audio.audioUrl;
    openAudioModal(url, audio.title);
  }
  return (
    <div className="d-flex d-row audio-card">
      <div className="audio-card__title">
        <div style={{ overflowY: "visible" }}>
          <div>
            <span className="audio-card__title_title">{audio.title}</span>
          </div>
          <span className="audio-card__title_author">{audio.author}</span>
          <br />
          <span className="audio-card__title_serie">{audio.serie}</span>
        </div>
      </div>
      <Button
        className="btn btn-primary"
        style={{ display: audio.audioUrl ? "block" : "none" }}
        tag="a"
        href={
          audio.isExternal
            ? audio.audioUrl
            : process.env.REACT_APP_API_URL + audio.audioUrl
        }
        onClick={(e) => {
          e.preventDefault();
          modaltoggle(audio);
        }}
      >
        <i className="fa fas fa-volume-up"></i>
      </Button>
    </div>
  );
};

export default AudioCard;
