import { ACTION_TYPES } from "../actions";

const initialState = {
  dbUser: {
    freeLimit: 20,
    regularGivings: [],
  },
  addresses: [],
  orders: [],
  newAddress: {},
  userForm: {},
  token: "",
};
export default function (state = initialState, action) {
  let regularGivingsFiltered;

  switch (action.type) {
    case ACTION_TYPES.USER_LOADED:
      return {
        ...state,
        dbUser: action.payload,
      };
    case ACTION_TYPES.REGULARGIVING_ADDED:
      regularGivingsFiltered = state.dbUser.regularGivings.filter(
        (r) => r.id !== action.payload.id
      );
      regularGivingsFiltered = [...regularGivingsFiltered, action.payload];
      return {
        ...state,
        dbUser: { ...state.dbUser, regularGivings: regularGivingsFiltered },
      };
    case ACTION_TYPES.REGULARGIVING_CANCELLED:
      regularGivingsFiltered = state.dbUser.regularGivings.filter(
        (r) => r.id !== action.payload
      );
      return {
        ...state,
        dbUser: { ...state.dbUser, regularGivings: regularGivingsFiltered },
      };
    case ACTION_TYPES.TOKEN_LOADED:
      return {
        ...state,
        token: action.payload,
      };
    case ACTION_TYPES.ADDRESSES_LOADED:
      return {
        ...state,
        addresses: action.payload,
      };
    case ACTION_TYPES.CHANGE_NEW_ADDRESS_FORM:
      return {
        ...state,
        newAddress: Object.assign({}, action.payload),
      };
    case ACTION_TYPES.CHANGE_USER_FORM:
      return {
        ...state,
        userForm: Object.assign({}, action.payload),
      };
    case ACTION_TYPES.CLEAN_NEW_ADDRESS_FORM:
      return {
        ...state,
        newAddress: {},
      };
    case ACTION_TYPES.ORDERS_LOADED:
      return {
        ...state,
        orders: action.payload,
      };
    case ACTION_TYPES.CLEAN_USER_FORM:
      return {
        ...state,
        userForm: {},
      };
    default:
      return state;
  }
}
