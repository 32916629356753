import { ACTION_TYPES } from "../actions";

const initialState = {
  selectedBooks: [],
  checkoutForm: {
    email: "",
    type: "free",
  },
  lastOrder: {},
};
export default function (state = initialState, action) {
  let existingItem;
  let currentBooks;
  let payload;
  let price;

  switch (action.type) {
    case ACTION_TYPES.ADD_BOOK_TO_CART:
      existingItem = state.selectedBooks.filter(
        (r) => r.code === action.payload.code
      );
      currentBooks = state.selectedBooks;
      payload = action.payload;
      price = action.payload.price;

      if (existingItem.length > 0) {
        currentBooks = state.selectedBooks.filter(
          (r) => r.code !== action.payload.code
        );
        payload = {
          ...payload,
          counter: ++existingItem[0].counter,
          lineVal: Math.round((existingItem[0].lineVal + price) * 100) / 100,
        };
      } else {
        payload = { ...payload, lineVal: price };
      }

      return {
        ...state,
        selectedBooks: [...currentBooks, payload],
      };
    case ACTION_TYPES.CHANGE_CART_QTY:
      existingItem = state.selectedBooks.filter(
        (r) => r.code === action.payload.code
      );
      currentBooks = state.selectedBooks;
      payload = action.payload;
      price = action.payload.price;

      if (existingItem.length > 0) {
        currentBooks = state.selectedBooks.filter(
          (r) => r.code !== action.payload.code
        );
        payload = {
          ...payload,
          counter: parseInt(action.payload.counter, 10),
          lineVal: Math.round(action.payload.counter * price * 100) / 100,
        };
      } else {
        payload = {
          ...payload,
          counter: action.payload.counter,
          lineVal: Math.round(action.payload.counter * price * 100) / 100,
        };
      }

      return {
        ...state,
        selectedBooks: [...currentBooks, payload],
      };
    case ACTION_TYPES.REMOVE_BOOK_FROM_CART:
      existingItem = state.selectedBooks.filter(
        (r) => r.code === action.payload.code
      );
      currentBooks = state.selectedBooks.filter(
        (r) => r.code !== action.payload.code
      );
      price = action.payload.price;
      payload = action.payload;
      const isOnlyItemRemove =
        existingItem.length > 0 && existingItem[0].counter > 1 && !payload.all;

      if (isOnlyItemRemove) {
        payload = {
          ...payload,
          counter: --existingItem[0].counter,
          lineVal: Math.round((existingItem[0].lineVal - price) * 100) / 100,
        };

        return {
          ...state,
          selectedBooks: [...currentBooks, payload],
        };
      } else {
        payload = { ...payload, lineVal: price };
      }

      return {
        ...state,
        selectedBooks: [...currentBooks],
      };
    case ACTION_TYPES.CHANGE_CHECKOUT_FORM:
      return {
        ...state,
        checkoutForm: Object.assign({}, action.payload),
      };
    case ACTION_TYPES.CLEAN_CHECKOUT_FORM:
      return {
        ...state,
        checkoutForm: Object.assign({}, initialState.checkoutForm),
      };
    case ACTION_TYPES.CART_POSTED:
      return {
        ...state,
        lastOrder: Object.assign({}, action.payload),
      };
    case ACTION_TYPES.CLEAN_CART:
      return initialState;
    default:
      return state;
  }
}
