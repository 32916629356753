import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import Sorter from "./sorter";
import { AUTHORS_SORT_TYPES } from "../../../../../../common/constants";
import { Button, Input, InputGroup, InputGroupText } from "reactstrap";
import Spinner from "../../../../../common/loader/components/spinner";

const AuthorFilterBar = (props) => {
  const { t } = useTranslation();

  useEffect(() => {
    props.changeSearchCriteria(
      { sort: AUTHORS_SORT_TYPES.most_titles },
      "authorsFilter",
      props.actionType
    );
  }, []);

  let timerId;

  const onSearchClicked = (e = null) => {
    e?.preventDefault();
    let interval = 900;
    if (e) interval = 1;
    clearTimeout(timerId);
    timerId = setTimeout(() => {
      props.getAuthors({ pageCount: 0 });
    }, interval);
  };

  function onSearchInputChange(e) {
    props.changeSearchCriteria(
      { criteria: e.target.value },
      "authorsFilter",
      props.actionType
    );
    onSearchClicked();
  }

  const authors_sort = {
    title: t("authors-sort-title"),
    onClick: ({ target }) => {
      props.changeSearchCriteria(
        { sort: target.value },
        "authorsFilter",
        props.actionType
      );
      onSearchClicked();
    },
    items: [
      {
        text: t("authors-sort-last-name"),
        value: AUTHORS_SORT_TYPES.last_name,
        selected: AUTHORS_SORT_TYPES.last_name === props.filterParams.sort,
      },
      {
        text: t("authors-sort-most-titles"),
        value: AUTHORS_SORT_TYPES.most_titles,
        selected: AUTHORS_SORT_TYPES.most_titles === props.filterParams.sort,
      },
    ],
  };

  const icon =
    props.authors.length < 1 ? (
      <Spinner style={{ height: "16px", color: "white" }} />
    ) : (
      <span className=" fa fa-search fa-4 " />
    );
  return (
    <form onSubmit={onSearchClicked}>
      <InputGroup size="sm">
        <Input
          type="text"
          value={props.filterParams.criteria ? props.filterParams.criteria : ""}
          onChange={onSearchInputChange}
          placeholder={t("search") + " " + t("last-name")}
          id="lastNameSearch"
        />
        <Sorter sorts={authors_sort} />
      </InputGroup>
    </form>
  );
};

export default AuthorFilterBar;
