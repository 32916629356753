import React, { useEffect, useState } from "react";
import {
  Card,
  Button,
  CardText,
  CardHeader,
  CardFooter,
  CardBody,
  ButtonGroup,
} from "reactstrap";
import ValidatedInput from "../../../../common/input/validated-input";
import { useTranslation } from "react-i18next";
import Spinner from "../../../../common/loader/components/spinner";

const UserTaxId = (props) => {
  const { t } = useTranslation();
  const [typedTaxId, setTypedTaxId] = useState();
  const [sending, setSending] = useState(false);

  useEffect(() => {
    setSending(false);
    setTypedTaxId("");
  }, [props.dbUser.taxId]);

  function saveData() {
    setSending(true);
    props.saveUserTaxId(props.token, props.dbUser?.userId, typedTaxId);
  }

  return (
    <Card className="mb-4">
      <CardHeader>{t("user-tax-id-header")}</CardHeader>
      <CardBody>
        <CardText>
          {t("taxId")}:{" "}
          {props.dbUser?.taxId && (
            <strong>
              {props.dbUser?.taxId}
              <br />
            </strong>
          )}
          <br />
          <ButtonGroup>
            <ValidatedInput
              type="string"
              className="form-control"
              errorMessage={t("input-error-message")}
              required={false}
              isValid={true}
              enabled={true}
              onChange={(value) => setTypedTaxId(value)}
              placeholder={t("user-tax-id-header")}
              value={typedTaxId}
              maxLength={40}
            />
            {sending ? (
              <Button style={{ width: "40px", height: "fit-content" }}>
                <Spinner />
              </Button>
            ) : (
              <Button onClick={saveData} style={{ height: "fit-content" }}>
                {t("save")}
              </Button>
            )}
          </ButtonGroup>
        </CardText>
      </CardBody>
      <CardFooter
        dangerouslySetInnerHTML={{
          __html: t("user-tax-id-details"),
        }}
      ></CardFooter>
    </Card>
  );
};

export default UserTaxId;
