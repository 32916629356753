import { connect } from "react-redux";
import { getAuthors, changeAuthorsSort } from "../../../actions";
import AuthorTab from "./components";

const mapDispatchToProps = (dispatch) => ({
  changeAuthorsSort: (sort) => dispatch(changeAuthorsSort(sort)),
});

const mapStateToProps = (state) => ({
  authorsSort: state.sorts.currentAuthorsSort,
  getAuthors: (params) => getAuthors(params),
  authors: state.authors.authors,
});

export default connect(mapStateToProps, mapDispatchToProps)(AuthorTab);
