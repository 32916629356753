import React, { useEffect } from "react";
import NavigationList from "../../navigation-list";

const SeriesNavigationList = ({
  loadSeries,
  bookSeries,
  isMobileFriendly = false,
}) => {
  useEffect(() => {
    if (bookSeries?.length < 1) loadSeries();
  }, []);

  return (
    <div>
      <NavigationList
        field={"serieId"}
        heading={"series"}
        items={bookSeries}
        isMobileFriendly={isMobileFriendly}
      />
    </div>
  );
};

export default SeriesNavigationList;
