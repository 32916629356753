import { ACTION_TYPES } from "../actions";

const initialState = {
  operationResult: {},
};
export default function (state = initialState, action) {
  switch (action.type) {
    case ACTION_TYPES.NEW_MESSAGE:
      return {
        ...state,
        operationResult: action.payload,
      };
    default:
      return state;
  }
}
