import React from "react";
import { useNavigate } from "react-router-dom";
import { Auth0Provider } from "@auth0/auth0-react";
import config from "./auth_config.json";

const Auth0ProviderWithHistory = ({ children }) => {
  const audienceTerminator = process.env.REACT_APP_API_URL.includes("localhost")
    ? "/api"
    : ":8443/api/";

  const navigate = useNavigate();

  const onRedirectCallback = (appState) => {
    navigate((appState && appState.returnTo) || window.location.pathname);
  };

  if (!navigator.cookieEnabled) {
    return children;
  }

  return (
    <Auth0Provider
      domain={config.domain}
      clientId={config.clientId}
      redirectUri={window.location.origin}
      audience={process.env.REACT_APP_API_URL + audienceTerminator}
      onRedirectCallback={onRedirectCallback}
    >
      {children}
    </Auth0Provider>
  );
};

export default Auth0ProviderWithHistory;
