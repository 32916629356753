import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Loader from "../../../../../components/common/loader";
import BookCard from "../../../../common/book-card";
import { useTranslation } from "react-i18next";

const Featured = (props) => {
  const { t, i18n } = useTranslation();
  const { language } = i18n;
  const [isLoading, setIsLoading] = useState(true);
  const [items, setItems] = useState([]);

  useEffect(() => {
    setIsLoading(true);
    loadData();
  }, [language, props.browserFilter]);

  function loadData() {
    props.getNovelties().then((data) => {
      setItems(data);
      setIsLoading(false);
    });
  }

  return (
    <div>
      <h2>
        {t("featured")}{" "}
        <Link type="button" className="btn btn-light btn-sm" to="/literature">
          {t("explore")}...
        </Link>
      </h2>
      <div
        style={{
          height: "80vh",
          overflowX: "hidden",
          overflowY: "scroll",
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
        }}
      >
        {isLoading && <Loader height="500" />}
        {!isLoading &&
          items.length > 0 &&
          items.map((b) => (
            <div key={b.id}>
              <BookCard book={b} />
            </div>
          ))}
      </div>
    </div>
  );
};

export default Featured;
