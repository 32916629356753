import React from "react";

import HoverImage from "./hover-image";
import books from "../../../../../resources/images/books.jpg";
import prison from "../../../../../resources/images/prison.jpg";
import audio from "../../../../../resources/images/mic.jpg";
import courses from "../../../../../resources/images/courses.jpg";

import { useTranslation } from "react-i18next";

const HoverImageContainer = () => {
  const { t } = useTranslation();
  return (
    <div className="row">
      <HoverImage
        title={t("literature")}
        target="literature"
        image={books}
        description={t("literature-description")}
        readmore={t("read-more")}
      />
      <HoverImage
        title={t("prison")}
        target="prison"
        image={prison}
        description={t("prison-description")}
        readmore={t("read-more")}
      />
      <HoverImage
        title={t("audiobooks")}
        target="audio"
        image={audio}
        description={t("audio-description")}
        readmore={t("read-more")}
      />
      <HoverImage
        title={t("courses")}
        target="courses"
        image={courses}
        description={t("courses-description")}
        readmore={t("read-more")}
      />
    </div>
  );
};

export default HoverImageContainer;
