import React from "react";
import { useNavigate } from "react-router-dom";

const HTMLContent = (props) => {
  const navigate = useNavigate();
  const contentClickHandler = (e) => {
    const targetLink = e.target.closest("a");
    if (!targetLink) return;
    e.preventDefault();

    const target = new URL(e.target.href);

    if (window.location.origin === target.origin) {
      navigate(target.pathname);
    } else {
      window.location = e.target.href;
    }
  };

  return (
    <div
      onClick={contentClickHandler}
      dangerouslySetInnerHTML={{ __html: props.content }}
    />
  );
};

export default HTMLContent;
