import React from "react";

//COMPONENTS
import TopDownloadsContainer from "./top-downloads";
import HoverImageContainer from "./hover-image";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import Featured from "./featured";
import SidebarFilters from "./sidebar-filters";

const Home = () => {
  const { t } = useTranslation();
  return (
    <div>
      <Helmet>
        <title>{t("user-welcome-header")}</title>
        <meta name="description" content={t("ministry-description-text")} />
      </Helmet>
      <div className="container">
        <div className="row">
          <div className="col-xl-2 col-md-3 d-md-block d-none pt-4">
            <SidebarFilters />
          </div>
          <div className="col-md-8 col-xl-10 col-12 pt-4">
            <Featured />
          </div>
        </div>
      </div>
      <hr />
      <div className="container">
        <div>
          <h2>{t("top-downloads")}</h2>
          <TopDownloadsContainer />
        </div>
      </div>
      <hr />
      <div className="container">
        <div>
          <h2>{t("our-ministries")}</h2>
          <br />
          <div className="mx-auto ">
            <p className="description text-justify ">
              {t("ministry-description-text")}
            </p>
          </div>
          <HoverImageContainer />
        </div>
      </div>
    </div>
  );
};

export default Home;
