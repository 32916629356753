import React from "react";
import { Link } from "react-router-dom";
import { Progress, Button, ButtonGroup } from "reactstrap";
import Search from "../../search";
import { useTranslation } from "react-i18next";

const CartButton = (props) => {
  const { t } = useTranslation();
  const counter = props.countTitlesInCart;
  const value = props.valInCart;
  const cartType = props.cartType;

  const setForm = (prop, value) => {
    let form = props.form;
    form[prop] = value;
    props.changeCheckoutForm(form);
  };

  return (
    <div className="options row py-2">
      <Search />
      <div className="col-sm-12 col-md-5 col-lg-4">
        <div style={{ flexGrow: "1", display: "flex" }}>
          <Link
            to="/cart"
            className="btn btn-outline-secondary p-0"
            style={{
              display: counter > 0 ? "inline-block" : "none",
              flexGrow: "1",
            }}
          >
            {t("cart")} <i className="fa fas fa-shopping-cart"></i>{" "}
            {` ${counter} `}{" "}
            {cartType === "free" && (
              <>
                <small>
                  ${value.toFixed(2)} / ${props.dbUser.freeLimit} {t("free")}
                </small>
                <Progress
                  className="bg-secondary"
                  value={(value / props.dbUser.freeLimit) * 100}
                  style={{ overflow: "visible", height: "7px" }}
                >
                  <Progress
                    bar
                    value={(value / props.dbUser.freeLimit) * 100}
                  ></Progress>
                </Progress>
              </>
            )}
            {cartType === "pay" && (
              <div className="small d-inline-block">
                ${Math.min(value, props.dbUser.freeLimit).toFixed(2)}{" "}
                {t("free")} | $
                {Math.max(value - props.dbUser.freeLimit, 0).toFixed(2)}{" "}
                {t("pay")}
              </div>
            )}
          </Link>
          <ButtonGroup
            className=""
            style={{
              display: counter > 0 ? "inline-block" : "none",
              padding: "0px",
            }}
          >
            <Button
              color={props.cartType === "free" ? "primary" : "secondary"}
              onClick={() => {
                if (props.dbUser.alreadyOrderedMo) {
                  alert(t("cart-already-ordered"));
                } else if (
                  props.valInCart > props.dbUser.freeLimit ||
                  (props.countTitlesInCart > 14 &&
                    0 + props.dbUser.freeLimit < 21)
                ) {
                  alert(
                    t("reduce-cart", { freeLimit: props.dbUser.freeLimit })
                  );
                } else {
                  setForm("type", "free");
                }
              }}
              size="sm"
            >
              {t("free")}
            </Button>
            <Button
              color={props.cartType === "pay" ? "primary" : "secondary"}
              onClick={() => {
                setForm("type", "pay");
              }}
              size="sm"
            >
              {t("pay")}
            </Button>
          </ButtonGroup>
        </div>
      </div>
    </div>
  );
};

export default CartButton;
