import React, { useState, useEffect } from "react";
import Loader from "../../../components/common/loader";
import HTMLContent from "../../../components/common/html-content";
import ImgManaged from "../../../components/common/img-managed";
import { Button, ButtonGroup, Col, Input, Row } from "reactstrap";
import { Link } from "react-router-dom";
import { uniqBy, getDownloadUrl } from "../../../common/util";
import { CHAPEL_LIBRARY } from "../../../common/constants";
import LanguageAvailable from "./language-available";

import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import "../styles/style.css";
import BookDownloadSection from "./book-download-section";
import BookArticles from "./book-articles";
import { Helmet } from "react-helmet";
import BookTopicList from "./book-topic-list";
import AuthorHighlight from "../../common/author-highlight";

const Book = (props) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [cartBook, setCartBook] = useState({});
  const { id } = useParams();

  const book = props.currentBook;
  const image = process.env.REACT_APP_API_URL + book.coverUrl;

  const addBook = (book) => {
    props.addBookToCart(book);
  };

  const removeBook = (book, all = true) => {
    props.removeBookFromCart(book, all);
  };

  const changeQty = (event, book) => {
    let count = event.target.value || 0;
    let _count = parseInt(count, 10);
    const newBook = {
      code: book.code,
      title: book.title,
      type: book.type,
      coverUrl: book.coverUrl,
      price: book.price,
      counter: _count,
    };
    props.changeCartQty(newBook);
  };

  useEffect(() => {
    props.getInfo(id).then((data) => {
      props.changeSelectedBook(data);
      setIsLoading(false);
    });
  }, [id]);

  useEffect(() => {
    let b = props.cartBook(id) ? props.cartBook(id) : {};
    setCartBook(b);
  }, [props.cartBook(id)]);

  function modalToggleAudio(book) {
    let url = process.env.REACT_APP_API_URL + book.audioUrl;
    props.openAudioModal(url, book.title);
  }
  function modalToggleAudioArticle(article) {
    let url = process.env.REACT_APP_API_URL + article.audioUrl;
    props.openAudioModal(url, article.title);
  }

  function modalTogglePrice(e) {
    e.preventDefault();
    let messageHeader = t("price");
    let messageBody = () => {
      return (
        <>
          <p>{t("price-explanation-body")}</p>
        </>
      );
    };
    props.openMessageModal(messageHeader, messageBody());
  }

  function renderAuthors(authors) {
    const uniq = uniqBy(authors, JSON.stringify);

    return (
      <>
        {uniq.length > 1 ? t("authors") + ": " : ""}
        {uniq.map((a, i) => {
          return (
            <span key={i}>
              <AuthorHighlight author={a} />
              {i === uniq.length - 1 ? "" : ", "}
            </span>
          );
        })}
      </>
    );
  }

  if (isLoading) {
    return <Loader height={850} />;
  }

  const fgbStringText = (function (b) {
    switch (b) {
      case "EN":
        return "Free Grace Broadcaster";
      case "ES":
        return "Portavoz de la Gracia";
      case "FR":
        return "Free Grace Broadcaster";
      default:
        return "Free Grace Broadcaster";
    }
  })(book.isoLangCode);
  const fgbString = !!book.number
    ? fgbStringText + " " + book.number + " - "
    : "";

  const parent = !!book.parent ? (
    <p>
      <span className="bold">{t("english-title")}:</span>
      <Link
        to={`/book/${book.parent.code}/${book.parent.friendlyTitle}`}
        className="btn"
      >
        {book.parent.title}
      </Link>
    </p>
  ) : null;

  const children = book.children.length > 0 ? LanguageAvailable(book, t) : null;

  return (
    <div className="book container" key={id}>
      <Helmet>
        <title>{`${book.title} - ${CHAPEL_LIBRARY}`}</title>
        <meta name="description" content="" />
      </Helmet>
      <div className="row justify-content-center" style={{ marginTop: "1em" }}>
        <div>
          <div
            className="col-sm-12"
            style={{ textAlign: "center", height: "400px" }}
          >
            <ImgManaged
              src={image}
              alt={book.title}
              type={book.type}
              width={250}
            />
          </div>
          <Link
            to={`/read/${book.code}`}
            className="btn btn-outline-primary"
            style={{
              display: book.epubUrl ? "block" : "none",
              width: "100%",
              maxWidth: "200px",
              marginInline: "auto",
            }}
          >
            <i className="fa fa-desktop fa-3" aria-hidden="true"></i>
            <span style={{ marginLeft: "1em" }}>{t("read-online")}</span>
          </Link>
        </div>
        <div className="col-sm-12 col-md-8">
          <div className="title">
            <h1>{fgbString + book.title}</h1>
          </div>
          <div>
            <div>
              {book.authors.length > 0 && <p>{renderAuthors(book.authors)}</p>}
              <HTMLContent content={book.description} />
              <BookArticles
                book={book}
                t={t}
                renderAuthors={renderAuthors}
                modalToggleAudioArticle={modalToggleAudioArticle}
              />
              {parent}
              <div className="row mb-3">
                <div className="col-4">
                  <span className="bold">{t("pages")}:</span> {book.pageCount}
                </div>
                <div className="col-4">
                  <span className="bold">{t("value")}:</span>
                  {" $"}
                  {(book.price + 0).toFixed(2)}{" "}
                  <button
                    onClick={(e) => modalTogglePrice(e)}
                    className="btn btn-sm btn-outline-secondary"
                  >
                    {t("what-this")}
                  </button>
                </div>
                <div className="col-4">
                  {" "}
                  <span className="bold">{t("format")}:</span>{" "}
                  {t(book.type.toLowerCase())}
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-4">
                  <span className="bold">{t("code")}:</span> {book.code}
                </div>
                <div className="col-4">
                  <span className="bold">{t("language")}:</span> {book.language}
                </div>
              </div>
            </div>
            {children}
          </div>
          <div className="options">
            <Button
              className="btn btn-outline-primary mb-3"
              style={{ display: book.audioUrl ? "inline-block" : "none" }}
              tag="a"
              href={process.env.REACT_APP_API_URL + book.audioUrl}
              onClick={(e) => {
                e.preventDefault();
                modalToggleAudio(book);
              }}
            >
              <i className="fa fas fa-volume-up"></i>
              <span style={{ marginLeft: "1em" }}>{t("listen-online")}</span>
            </Button>
            <BookDownloadSection
              book={book}
              t={t}
              getDownloadUrl={getDownloadUrl}
            />
          </div>
          {book.hasPrintableVersion && props.canAddToCart && (
            <Button
              onClick={() => addBook(book)}
              className="btn btn-info col-md-6 col-sm-12"
              style={{
                display:
                  book.hasPrintableVersion && props.canAddToCart
                    ? "block"
                    : "none",
              }}
            >
              <i className="fa fas fa-shopping-cart"></i>
              <span style={{ marginLeft: "1em" }}>{t("add-to-cart")}</span>
            </Button>
          )}
          {!props.canAddToCart && (
            <Row>
              <Col>
                <Button
                  onClick={() => removeBook(book)}
                  className="btn btn-danger col-md-8 col-sm-12"
                  style={{
                    display: !props.canAddToCart ? "block" : "none",
                  }}
                >
                  <i className="fa fas fa-shopping-cart"></i>
                  <span style={{ marginLeft: "1em" }}>
                    {t("remove-from-cart")}
                  </span>
                </Button>
              </Col>
              {Object.keys(cartBook).length > 0 && (
                <Col>
                  <Row>
                    <Col>
                      <ButtonGroup>
                        <Button color="info" onClick={() => addBook(book)}>
                          <i className="fa fas fa-chevron-up"></i>
                        </Button>
                        <Button
                          color="danger"
                          onClick={() => removeBook(book, false)}
                        >
                          <i className="fa fas fa-chevron-down"></i>
                        </Button>
                      </ButtonGroup>
                    </Col>
                    <Col>
                      <Input
                        type="text"
                        name="counter"
                        value={cartBook.counter}
                        onChange={(e) => changeQty(e, book)}
                      />
                    </Col>
                  </Row>
                </Col>
              )}
            </Row>
          )}
          <BookTopicList bookCode={book.code} />
        </div>
      </div>
    </div>
  );
};

export default Book;
