import React from "react";
import { Link } from "react-router-dom";
import ContentContainer from "../../../../components/common/content-container";
import BookCard from "../../../../components/common/book-card";
import FilterBar from "../../../../components/common/filter-bar";
import { useTranslation } from "react-i18next";
import { Button, UncontrolledCollapse } from "reactstrap";

const LiteratureTab = (props) => {
  const { t } = useTranslation();

  return (
    <div className="container">
      <div className="mt-4">
        <div className=" d-flex justify-content-between align-items-center">
          <h1>{t("literature")}</h1>
          <Button
            color="outline-primary"
            id="toggler"
            size="sm"
            style={{
              marginBottom: "1rem",
            }}
          >
            {t("how-to-order")}/{t("donate")}
          </Button>
        </div>

        <div>
          <UncontrolledCollapse toggler="#toggler">
            <p className="text-justify">
              <span> {t("literature-header")}</span>{" "}
              <Link to="/donate" className="btn btn-outline-primary btn-sm">
                {t("donate")}
              </Link>
            </p>
            <div className="alert alert-warning">
              <span>{t("literature-warning")}</span>{" "}
              <Link
                to="/about/distributors"
                className="btn btn-outline-primary btn-sm"
              >
                {t("how-to-order")}
              </Link>{" "}
              <span>{t("literature-warning-2")}</span>
            </div>
          </UncontrolledCollapse>
        </div>
      </div>

      <FilterBar
        stateHandler="literatureFilter"
        actionType={props.filterActionType}
        searchFunction={props.getBooks}
      />
      <div>
        <ContentContainer
          get={props.getBooks}
          reducer={props.literatureBooks}
          content={(data) => <BookCard key={data.id} book={data}></BookCard>}
        />
      </div>
    </div>
  );
};

export default LiteratureTab;
