import React, { useEffect, useState } from "react";
import { TabContent, TabPane, Nav, NavItem, NavLink, Row } from "reactstrap";
import BookCard from "../../../../components/common/book-card";
import AudioCard from "../../../../components/common/audio-card";
import CourseCard from "../../../../components/common/course-card";
import { redirectToBook } from "../../../../common/util";
import FilterBar from "../../../../components/common/filter-bar";
import { Helmet } from "react-helmet";
import { CHAPEL_LIBRARY } from "../../../../common/constants";
import { useTranslation } from "react-i18next";
import ScrollToTop from "../../../common/scroll-to-top";

const Results = (props) => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState("1");

  useEffect(() => {
    if (props.searchQuerying) {
      setActiveTab("1");
    } else {
      setActiveTab(
        props.books.length > 0 ? "1" : props.audios.length > 0 ? "2" : "3"
      );
    }
    window.scrollBy(0, -79);
  }, [props.searchQuerying, props.books, props.audios]);

  function toggle(tab) {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  }

  function loadBooks(books) {
    return books.map((item, i) => (
      <BookCard book={item} key={i} redirect={redirectToBook} />
    ));
  }

  function loadAudios(audios) {
    return audios.map((item, i) => <AudioCard audio={item} key={i} />);
  }

  function loadCourses(courses) {
    return courses.map((item, i) => <CourseCard course={item} key={i} />);
  }

  return (
    <div style={{ height: "70vh", marginBottom: "3em" }}>
      <Helmet>
        <title>
          {t("search")} - {CHAPEL_LIBRARY}
        </title>
        <meta name="description" content="" />
      </Helmet>
      <ScrollToTop />
      <Nav tabs>
        <NavItem>
          <NavLink
            className={activeTab === "1" ? "active" : ""}
            onClick={() => {
              toggle("1");
            }}
          >
            {props.searchQuerying && (
              <i className="fa fa-spinner" aria-hidden="true"></i>
            )}
            {!props.searchQuerying && (
              <i className="fa fa-bookmark" aria-hidden="true"></i>
            )}
            <span>
              <b>{t("books")}: </b>
              {props.books.length}
            </span>
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={activeTab === "2" ? "active" : ""}
            onClick={() => {
              toggle("2");
            }}
          >
            <i className="fa fa-headphones" aria-hidden="true"></i>
            <span>
              <b>{t("audio")}:</b> {props.audios.length}
            </span>
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={activeTab === "3" ? "active" : ""}
            onClick={() => {
              toggle("3");
            }}
          >
            <i className="fa fa-graduation-cap" aria-hidden="true"></i>
            <span>
              <b>{t("courses")}:</b> {props.courses.length}
            </span>
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent
        activeTab={activeTab}
        style={{ height: "inherit", overflow: "scroll", overflowX: "hidden" }}
      >
        <TabPane tabId="1">
          <FilterBar
            stateHandler="mainFilter"
            actionType={props.filterActionType}
            searchFunction={props.getBooks}
          />
          <Row style={{ justifyContent: "center" }}>
            {loadBooks(props.books)}
          </Row>
        </TabPane>
        <TabPane tabId="2">
          <Row style={{ justifyContent: "center" }}>
            {loadAudios(props.audios)}
          </Row>
        </TabPane>
        <TabPane tabId="3">
          <Row style={{ justifyContent: "center" }}>
            {loadCourses(props.courses)}
          </Row>
        </TabPane>
      </TabContent>
    </div>
  );
};

export default Results;
