import React from "react";
import AuthorCard from "../../../../components/common/author-card";
import { useTranslation } from "react-i18next";
import ContentContainer from "../../../common/content-container/";
import AuthorFilterBar from "./author-filter-bar";

const AuthorTab = (props) => {
  const { t } = useTranslation();
  return (
    <>
      <div className="container">
        <div className="text-relevant">
          <div className="text-justify">
            <h1>{t("authors")}</h1>
            <div>
              <AuthorFilterBar />
              <ContentContainer
                get={props.getAuthors}
                reducer={props.authors}
                content={(data, i) => (
                  <AuthorCard key={data.code} author={data}></AuthorCard>
                )}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AuthorTab;
